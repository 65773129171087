import { useAccount, useMsal } from '@azure/msal-react';

// Types.
import { IB2CAccount } from 'types';

type UseB2CAccount = [IB2CAccount];

export const useB2CAccount = (): UseB2CAccount => {
  const { accounts } = useMsal();
  return [useAccount(accounts[0] || {}) as IB2CAccount];
};
