import {
  IEntitlement,
  IError,
  IQueryHookOptions,
  IUpdateProfileEntitlementRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateProfileEntitlement } from 'services';

type UpdateProfileEntitlementFunction = UseMutateAsyncFunction<
  IEntitlement,
  IError,
  IUpdateProfileEntitlementRequest
>;

type UseUpdateProfileEntitlement = [
  UpdateProfileEntitlementFunction,
  IQueryHookOptions,
];

export const useUpdateProfileEntitlements = (
  profileId = '',
  accessKey = '',
): UseUpdateProfileEntitlement => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IEntitlement,
    IError,
    IUpdateProfileEntitlementRequest
  >(
    (values: IUpdateProfileEntitlementRequest) =>
      updateProfileEntitlement(profileId, accessKey, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles', profileId, 'entitlements']);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
