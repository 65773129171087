import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import { ReactElement } from 'react';
import Paginate from 'react-paginate';

// Styles.
import styles from './Pagination.module.scss';

interface IPaginationProps {
  page?: number;
  onPageChange?: (page: number) => void;
  pageCount: number;
}

export const Pagination = (props: IPaginationProps): ReactElement => {
  const { page = 0, onPageChange, pageCount } = props;

  return (
    <Paginate
      activeClassName={styles.active}
      containerClassName={styles.container}
      forcePage={page - 1}
      nextClassName={styles.next}
      nextLabel={<ChevronRightRounded />}
      onPageChange={({ selected }) => onPageChange?.(selected + 1)}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      previousClassName={styles.previous}
      previousLabel={<ChevronLeftRounded />}
      marginPagesDisplayed={1}
    />
  );
};
