import { FormCheckbox } from '@comptia-sso/core';
import { ReactElement } from 'react';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './PermissionsMatrix.module.scss';

// Types.
import { PermissionLabelMap, Permissions } from 'types';

interface IPermissionsMatrix {
  open: boolean;
}

export const PermissionsMatrix = (props: IPermissionsMatrix): ReactElement => {
  const { open } = props;
  const { isEditing } = useEditableContext();

  const renderPermission = (permission: Permissions) => (
    <div className={styles.detail}>
      <strong className={styles.label} id={`labelFor-${permission}`}>
        {PermissionLabelMap[permission]}
      </strong>
      <FormCheckbox
        aria-labelledby={`labelFor-${permission}`}
        containerStyle={{ justifyContent: 'right' }}
        name={permission}
        disabled={!isEditing}
      />
    </div>
  );

  return (
    <>
      {open && (
        <>
          <div className={styles.container}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <div className={styles.detail}>
                  <strong className={styles.header}>User Account</strong>
                </div>
                {renderPermission(Permissions.EditProfile)}
                {renderPermission(Permissions.MergeProfile)}
                {renderPermission(Permissions.ChangeProfileName)}
                {renderPermission(Permissions.ChangePassword)}
                {renderPermission(Permissions.ChangeEmail)}
                {renderPermission(Permissions.EditEntitlements)}
                {renderPermission(Permissions.ChangePearsonVueAndComptiaIds)}
                {renderPermission(Permissions.changeSuspensions)}
              </div>
              <div className={styles.column}>
                <div className={styles.detail}>
                  <strong className={styles.header}>
                    Admin User Management
                  </strong>
                </div>
                {renderPermission(Permissions.ChangePermissions)}
                <div className={styles.detail}>
                  <strong className={styles.header}>Access Keys</strong>
                </div>
                {renderPermission(Permissions.CreateAccessKeys)}
                {renderPermission(Permissions.EditAccessKeys)}
              </div>
              <div className={styles.column}>
                <div className={styles.detail}>
                  <strong className={styles.header}>
                    Announcement Management
                  </strong>
                </div>
                {renderPermission(Permissions.EditAnnouncements)}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
