import {
  IAnnouncement,
  IError,
  LoadingOverlay,
  LanguageNames,
  Languages,
  getEnumKeyByEnumValue,
  useToast,
  TextFieldTypes,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import {
  Card,
  EditableCalendar,
  EditableContent,
  EditableDetail,
  EditableTime,
} from 'components';

// Hooks.
import { useUpdateAnnouncement } from 'hooks';

// Styles.
import styles from './AnnouncementDetails.module.scss';

// Types.
import { IAccount } from 'types';

// Validations.
import { validationSchema } from './validationSchema';
import { useNavigate } from 'react-router-dom';

interface IAnnouncementDetailsProps {
  announcement: IAnnouncement;
  account?: IAccount;
  mode: string;
}

export const AnnouncementDetails = (
  props: IAnnouncementDetailsProps,
): ReactElement => {
  const { announcement, account, mode } = props;
  const [isEditing, setIsEditing] = useState<boolean>(mode !== 'edit');
  const [popToast] = useToast();
  const [updateAnnouncement] = useUpdateAnnouncement(announcement?.id);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      endDate: announcement?.endDate || '',
      endTime: announcement?.endDate || '',
      heading: announcement?.heading || '',
      language: announcement?.language ?? 'en',
      message: announcement?.message || '',
      sortOrder: announcement?.sortOrder || 0,
      startDate: announcement?.startDate || '',
      startTime: announcement?.startDate || '',
    },
    onSubmit: async (values) => {
      const startTime = new Date(values.startTime);
      const startDateTime = new Date(values.startDate).setUTCHours(
        startTime.getUTCHours(),
        startTime.getUTCMinutes(),
      );
      const endTime = new Date(values.endTime);
      const endDateTime = new Date(values.endDate).setUTCHours(
        endTime.getUTCHours(),
        endTime.getUTCMinutes(),
      );

      values.startDate = new Date(startDateTime).toISOString();
      values.endDate = new Date(endDateTime).toISOString();
      values.sortOrder = !values.sortOrder ? 0 : values.sortOrder;
      if (account?.permissions?.editAnnouncements) {
        try {
          await updateAnnouncement(values);
          setIsEditing(false);
          popToast('Announcement successfully updated.');
          if (mode !== 'edit') {
            navigate('/announcements');
          }
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <div className={styles.content}>
      <EditableContent
        canEdit={account?.permissions?.editAnnouncements ?? false}
        isEditing={isEditing}
        onCancel={() => {
          formik.resetForm();
          if (mode !== 'edit') {
            navigate('/announcements');
          }
          setIsEditing(false);
        }}
        onEdit={() => setIsEditing(true)}
        formik={formik}
        title="Announcement"
      >
        <Card className={styles.grid}>
          <EditableDetail
            containerClassName={styles.key}
            label="Heading"
            name="heading"
          />
          <EditableDetail
            containerClassName={styles.title}
            label="Message"
            name="message"
            required
            textarea
          />
          <EditableDetail
            label="Language"
            name="language"
            renderValue={(value = '') =>
              getEnumKeyByEnumValue(Languages, value)
            }
            required
            select
          >
            <option value={Languages.English}>{LanguageNames.English}</option>
            <option value={Languages.Japanese}>{LanguageNames.Japanese}</option>
          </EditableDetail>
          <EditableDetail
            label="Sort Order"
            name="sortOrder"
            type={TextFieldTypes.Number}
          />
          <EditableCalendar label="Start Date (UTC)" name="startDate" />
          <EditableCalendar label="End Date (UTC)" name="endDate" />
          <EditableTime label="Start Time (UTC)" name="startTime" />
          <EditableTime label="End Time (UTC)" name="endTime" />
        </Card>
      </EditableContent>
      <LoadingOverlay
        isOpen={formik.isSubmitting}
        text="Updating Announcement"
      />
    </div>
  );
};
