import {
  Button,
  ButtonTypes,
  FormTextField,
  IError,
  IProfile,
  LoadingOverlay,
  TextFieldTypes,
  useToast,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import { Card, ConfirmationModal } from 'components';

// Hooks.
import { useSetPassword } from 'hooks';

// Styles.
import styles from './PasswordReset.module.scss';

// Types.
import { IAccount } from 'types';

// Validations.
import { validationSchema } from './validationSchema';

interface IPasswordReset {
  account?: IAccount;
  profile?: IProfile;
}

export const PasswordReset = (props: IPasswordReset): ReactElement => {
  const { account, profile } = props;
  const [isConfirming, setIsConfirming] = useState(false);
  const [popToast] = useToast();
  const [setPassword] = useSetPassword();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profile?.email ?? '',
      newPassword: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (account?.permissions?.editProfile) {
        if (!isConfirming) {
          setIsConfirming(true);
        } else {
          setIsConfirming(false);

          try {
            await setPassword(values);
            resetForm();
            popToast('Password set successfully.');
          } catch (error) {
            popToast(
              (error as IError)?.message ||
                'An unexpected error occurred. Please try again later.',
            );
          }
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  const handleConfirm = () => formik.submitForm();
  const handleCancel = () => setIsConfirming(false);

  if (!profile) return <></>;

  if (profile?.authenticationSource === 'socialIdpAuthentication') {
    return (
      <Card className={styles.social}>
        <span className={styles.text}>
          This is a social account. The password cannot be changed.
        </span>
      </Card>
    );
  }

  return (
    <Card className={styles.content}>
      <h3>User Password</h3>
      <form noValidate onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <FormTextField
            className={styles.input}
            label="Password"
            name="newPassword"
            required
            type={TextFieldTypes.Password}
            disabled={profile?.archived}
          />
          <Button
            className={styles.button}
            type={ButtonTypes.Submit}
            disabled={profile?.archived}
          >
            Set Password
          </Button>
        </FormikProvider>
      </form>
      <ConfirmationModal
        confirmationMessage="Are you sure you want to set this password?"
        open={isConfirming}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <LoadingOverlay
        isOpen={!formik.isValidating && formik.isSubmitting}
        text="Setting Password"
      />
    </Card>
  );
};
