import { FormTextField, ITextFieldProps } from '@comptia-sso/core';
import Close from '@material-ui/icons/Close';
import { useField, useFormikContext } from 'formik';
import { ReactElement, useEffect, useRef, useState } from 'react';

// Styles.
import styles from './SearchFormTextField.module.scss';

type ISearchTextFieldProps = ITextFieldProps & {
  name: string;
};

export const SearchFormTextField = (
  props: ISearchTextFieldProps,
): ReactElement => {
  const { name, select } = props;
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | HTMLSelectElement>(null);
  const formik = useFormikContext();
  const [field] = useField(name);

  const handleClear = () => {
    formik.setFieldValue(name, '');
  };

  useEffect(() => {
    setIsFilled(field.value.toString().length > 0);
  }, [field.value]);

  return (
    <FormTextField
      {...props}
      className={styles.container}
      endIcon={
        isFilled &&
        !select && (
          <button className={styles.close} onClick={handleClear} type="button">
            <Close />
          </button>
        )
      }
      filledClassName={styles.filled}
      focusedClassName={styles.focused}
      inputClassName={styles.input}
      labelClassName={styles.label}
      ref={inputRef}
    />
  );
};
