import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, ReactNode, useMemo } from 'react';

// Styles.
import styles from './Detail.module.scss';

interface IDetailProps {
  emptyMessage?: string;
  label: string;
  value?: ReactNode | boolean | number | string;
  valueClassName?: string;
}

export const Detail = (props: IDetailProps): ReactElement => {
  const { emptyMessage = 'None', label, value, valueClassName } = props;
  const hasValue = useMemo(
    () => value !== null && value !== undefined && value !== '',
    [value],
  );
  const id = useMemo(() => uniqueId('detail'), []);

  return (
    <div className={styles.detail}>
      <strong className={styles.label} id={id}>
        {label}
      </strong>
      <span
        aria-labelledby={id}
        className={classNames(styles.value, valueClassName)}
      >
        {hasValue ? value : emptyMessage}
      </span>
    </div>
  );
};
