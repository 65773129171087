import { ISuspension, IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getProfileSuspensions } from 'services';

type UseProfileSuspensions = [ISuspension[], IQueryHookOptions];

export const useProfileSuspensions = (
  profileId = '',
): UseProfileSuspensions => {
  const {
    data = [] as ISuspension[],
    error,
    isError,
    isLoading,
  } = useQuery<ISuspension[], IError>(
    ['profiles', profileId, 'suspensions'],
    () => getProfileSuspensions(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
