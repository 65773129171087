import {
  IError,
  IProfile,
  IQueryHookOptions,
  IUpdateComptiaIdandPearsonVueId,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateComptiaIdAndPearsonVueId } from 'services';

type UpdateComptiaIdandPearsonVueIdFunction = UseMutateAsyncFunction<
  IProfile,
  IError,
  IUpdateComptiaIdandPearsonVueId
>;

type UseUpdateComptiaIdandPearsonVueId = [
  UpdateComptiaIdandPearsonVueIdFunction,
  IQueryHookOptions,
];

export const useUpdateComptiaIdandPearsonVueId = (
  profileId = '',
): UseUpdateComptiaIdandPearsonVueId => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfile,
    IError,
    IUpdateComptiaIdandPearsonVueId
  >(
    (values: IUpdateComptiaIdandPearsonVueId) =>
      updateComptiaIdAndPearsonVueId(profileId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles', profileId]);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
