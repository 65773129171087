import {
  IError,
  IQueryHookOptions,
  IUserLoggingAnalytics,
} from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getUserLoggingAnalytics } from 'services';

type UseLoggingAnalytics = [IUserLoggingAnalytics[], IQueryHookOptions];

export const useUserLoggingAnalytics = (
  profileId = '',
): UseLoggingAnalytics => {
  const {
    data = [] as IUserLoggingAnalytics[],
    error,
    isError,
    isLoading,
  } = useQuery<IUserLoggingAnalytics[], IError>(
    ['profiles', profileId, 'userLoggingAnalytics'],
    () => getUserLoggingAnalytics(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
