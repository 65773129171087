import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { FormEvent, ReactElement } from 'react';

// Components.
import { ProfileSearchForm } from './ProfileSearchForm';
import { ProfileSearchResults } from './ProfileSearchResults';

// Hooks.
import { useAppDispatch, useAppSelector, useSearchProfiles } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Store.
import { profileSearchActions } from 'store/slices';

const { initializeSearch, setRequest, setResponse } = profileSearchActions;

export const ProfileSearchView = (): ReactElement => {
  const [popToast] = useToast();
  const dispatch = useAppDispatch();
  const { continuationToken, hasSearched, pageSize, request, results } =
    useAppSelector((state) => state.profileSearch);
  const [searchProfiles] = useSearchProfiles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: request,
    onSubmit: async (values) => {
      const { firstName, lastName, ...request } = values;
      let searchString = values.searchString;
      if (values.searchField === 'Name') {
        searchString = `${firstName.trim()}||${lastName.trim()}`;
      }

      try {
        const response = await searchProfiles({
          ...request,
          continuationToken,
          pageSize,
          searchString,
        });
        dispatch(setRequest(values));
        dispatch(setResponse(response));
      } catch (error) {
        popToast(
          (error as IError)?.message ||
            'An unexpected error occurred. Please try again later.',
        );
      }
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(initializeSearch(false));
    formik.handleSubmit(e);
  };

  const handleClear = () => {
    dispatch(initializeSearch(true));
    formik.resetForm();
  };

  const handleLoadMore = () => {
    formik.submitForm();
  };

  return (
    <>
      <ContentLayout
        header={
          <FormikProvider value={formik}>
            <ProfileSearchForm
              hasSearched={hasSearched}
              isDirty={formik.dirty}
              onClear={handleClear}
              onSearch={handleSubmit}
              searchField={formik.values.searchField}
            />
          </FormikProvider>
        }
        content={
          <ProfileSearchResults
            continuationToken={continuationToken}
            hasSearched={hasSearched}
            onLoadMore={handleLoadMore}
            profiles={results}
          />
        }
      />
      <LoadingOverlay isOpen={formik.isSubmitting} text="Searching Profiles" />
    </>
  );
};
