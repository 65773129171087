import { IError, IProfile, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getProfile } from 'services';

type UseProfile = [IProfile, IQueryHookOptions];

export const useProfile = (profileId = ''): UseProfile => {
  const {
    data = {} as IProfile,
    error,
    isError,
    isLoading,
  } = useQuery<IProfile, IError>(
    ['profiles', profileId],
    () => getProfile(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
