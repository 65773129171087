import { IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { setEmail } from 'services';

// Types.
import { ISetEmailRequest } from 'types';

type UseSetEmailFunction = UseMutateAsyncFunction<
  void,
  unknown,
  ISetEmailRequest
>;

type UseSetEmail = [UseSetEmailFunction, IQueryHookOptions];

export const useSetEmail = (profileId = ''): UseSetEmail => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    void,
    IError,
    ISetEmailRequest
  >((values: ISetEmailRequest) => setEmail(profileId, values), {
    onSuccess: () => {
      queryClient.invalidateQueries(['profiles', profileId]);
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
