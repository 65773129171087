import classNames from 'classnames';
import { ReactElement } from 'react';
import { NavLink, NavLinkProps, useMatch } from 'react-router-dom';

// Styles.
import styles from './Nav.module.scss';

const CustomNavLink = (props: NavLinkProps) => (
  <NavLink
    {...props}
    className={({ isActive }) =>
      classNames(props.className, { [styles.active]: isActive })
    }
  />
);

export const Nav = (): ReactElement => {
  const profileMatch = useMatch('profiles/*');

  return (
    <nav className={styles.nav}>
      <CustomNavLink
        className={classNames(styles.navLink, {
          [styles.active]: !!profileMatch,
        })}
        end
        to="/"
      >
        Profile Search
      </CustomNavLink>
      <CustomNavLink className={styles.navLink} to="/access-keys">
        Access Key Search
      </CustomNavLink>
      <CustomNavLink className={styles.navLink} to="/authorization">
        Authorization
      </CustomNavLink>
      <CustomNavLink className={styles.navLink} to="/announcements">
        Announcements
      </CustomNavLink>
    </nav>
  );
};
