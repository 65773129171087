import { yup } from '@comptia-sso/core';

export const validationSchema = (): yup.SchemaOf<unknown> =>
  yup.object().shape({
    endDate: yup
      .date()
      .required('Required')
      .test(
        'endDateShouldBeAfterStartDate',
        'End date should be after start date',
        function () {
          return this.parent.startDate < this.parent.endDate;
        },
      ),
    startDate: yup.date().required('Required'),
  });
