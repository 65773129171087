/* istanbul ignore file */
import { ToastProvider } from '@comptia-sso/core';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'providers';
import { store } from 'store';
import { appInsights } from 'utils';
import '@comptia-sso/core/dist/styles.css';
import './index.scss';
import { App } from './app';
import reportWebVitals from './reportWebVitals';

appInsights.loadAppInsights();

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: 30000,
    },
  },
});

render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <AuthProvider>
          <ToastProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </ToastProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
