import { IZendeskTicket, IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getProfileZendeskTickets } from 'services';

type UseProfileZendeskTickets = [IZendeskTicket[], IQueryHookOptions];

export const useProfileZendeskTickets = (
  profileId = '',
): UseProfileZendeskTickets => {
  const {
    data = [] as IZendeskTicket[],
    error,
    isError,
    isLoading,
  } = useQuery<IZendeskTicket[], IError>(
    ['profiles', profileId, 'zendeskTickets'],
    () => getProfileZendeskTickets(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
