import {
  IError,
  IProfile,
  Spinner,
  formatDate,
  useToast,
} from '@comptia-sso/core';
import { ReactElement, useEffect, useState } from 'react';

// Components
import { Card } from 'components';
import { ActivityModal } from '../ActivityModal';

// Hook.
import { useUserActivity } from 'hooks';

// Styles.
import styles from './UserActivity.module.scss';

interface IUserActivity {
  profile?: IProfile;
}

export const UserActivity = (props: IUserActivity): ReactElement => {
  const { profile } = props;
  const [open, setOpen] = useState(false);
  const [popToast] = useToast();
  const [
    userActivities,
    { error: userActivitiesError, isLoading: isLoadingUserActivities },
  ] = useUserActivity(profile?.id);

  useEffect(() => {
    if (userActivitiesError) {
      popToast(
        (userActivitiesError as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [userActivitiesError]);

  if (!profile) return <></>;

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const renderWhenNone = () => {
    return (
      <>
        <span className={styles.value}>None</span>
      </>
    );
  };

  const renderWhenSome = () => {
    return (
      <>
        <span
          className={styles.viewAll}
          onClick={() => handleModalOpen()}
          aria-label="View All User Activity"
        >
          View All &gt;
        </span>
        <ActivityModal
          open={open}
          profile={profile}
          onClose={handleModalOpen}
          userActivities={userActivities}
        />
      </>
    );
  };

  return (
    <Card className={styles.content}>
      <h3>User Activity</h3>
      {profile && !isLoadingUserActivities ? (
        <>
          <div className={styles.detail}>
            <div className={styles.label}>Last Login</div>
            <div className={styles.values}>
              <span className={styles.valueDate}>
                {formatDate(profile?.lastLoginDate)}
              </span>
            </div>
          </div>
          <div className={styles.dd}>
            {userActivities.length > 1 ? renderWhenSome() : renderWhenNone()}
          </div>
        </>
      ) : (
        <Spinner className={styles.spinner} />
      )}
    </Card>
  );
};
