import { IError, IQueryHookOptions, IAnnouncement } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getAnnouncements } from 'services';

type UseAnnouncements = [IAnnouncement[], IQueryHookOptions];

export const useAnnouncements = (): UseAnnouncements => {
  const {
    data = [] as IAnnouncement[],
    error,
    isError,
    isLoading,
  } = useQuery<IAnnouncement[], IError>(['announcements'], () =>
    getAnnouncements(),
  );
  return [data, { error, isError, isLoading }];
};
