import { IError, IProfile, Spinner, useToast } from '@comptia-sso/core';
import chunk from 'lodash/chunk';
import { ReactElement, useEffect, useMemo, useState } from 'react';

// Components.
import { Card, Pagination } from 'components';
import { EntitlementCard } from './EntitlementCard';

// Hooks.
import { useProfileEntitlements } from 'hooks';

// Styles.
import styles from './ManageUserEntitlements.module.scss';

// Types.
import { IAccount } from 'types';

interface IManageUserEntitlementsProps {
  account?: IAccount;
  profile?: IProfile;
}

const totalPerPage = 10;

export const ManageUserEntitlements = (
  props: IManageUserEntitlementsProps,
): ReactElement => {
  const { account, profile } = props;
  const [popToast] = useToast();
  const [
    entitlements,
    { error: entitlementsError, isLoading: isLoadingEntitlements },
  ] = useProfileEntitlements(profile?.id);
  const [page, setPage] = useState(1);

  const pageCount = useMemo(
    () => Math.ceil((entitlements?.length ?? 0) / totalPerPage),
    [entitlements],
  );

  const entitlementsForPage = useMemo(
    () => chunk(entitlements, totalPerPage)[page - 1],
    [page, entitlements],
  );

  useEffect(() => {
    if (entitlementsError) {
      popToast(
        (entitlementsError as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [entitlementsError]);

  return (
    <section title="User Entitlements">
      <header>
        <h2>User Entitlements</h2>
      </header>
      {profile && !isLoadingEntitlements ? (
        <div className={styles.cards}>
          {(entitlements?.length ?? 0) > 0 ? (
            <>
              {entitlementsForPage.map((entitlement, index) => (
                <EntitlementCard
                  account={account}
                  entitlement={entitlement}
                  key={entitlement?.accessKey || index}
                  profile={profile}
                />
              ))}
              {pageCount > 1 && (
                <Pagination
                  onPageChange={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              )}
            </>
          ) : (
            <Card className={styles['no-entitlements']}>
              This profile has no entitlements.
            </Card>
          )}
        </div>
      ) : (
        <Card>
          <Spinner className={styles.spinner} />
        </Card>
      )}
    </section>
  );
};
