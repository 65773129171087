import { ISwitchProps, Switch } from '@comptia-sso/core';
import { useField, useFormikContext } from 'formik';
import { useCallback, ReactElement } from 'react';

// Components.
import { Detail } from 'components';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './EditableSwitch.module.scss';

type IEditableSwitchProps = ISwitchProps & {
  label: string;
  name: string;
  renderValue?: (value?: boolean) => string;
};

export const EditableSwitch = (props: IEditableSwitchProps): ReactElement => {
  const { label, name, onChange, renderValue, ...switchProps } = props;
  const { isEditing } = useEditableContext();
  const { isSubmitting } = useFormikContext();
  const [field] = useField(name);

  const handleChange = useCallback(
    (e) => {
      field.onChange(e);
      onChange?.(e);
    },
    [field, onChange],
  );

  return (
    <div className={styles.container}>
      <Detail
        label={label}
        value={renderValue ? renderValue(field.value) : field.value}
      />
      {isEditing && (
        <Switch
          {...switchProps}
          {...field}
          disabled={isSubmitting}
          name={name}
          onChange={handleChange}
        />
      )}
    </div>
  );
};
