import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components.
import { ManageProfileHeader } from './ManageProfileHeader';
import { ManageProfileContent } from './ManageProfileContent';

// Hooks.
import { useAccount, useProfile } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Styles.
import styles from './ProfileEditView.module.scss';

export const ProfileEditView = (): ReactElement => {
  const { id } = useParams();
  const [popToast] = useToast();
  const [account] = useAccount();
  const [profile, { error: profileError, isLoading: isLoadingProfile }] =
    useProfile(id);

  useEffect(() => {
    if (profileError) {
      popToast(
        (profileError as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [profileError]);

  const archivedClass = profile?.archived ? styles.archived : '';
  return (
    <>
      <ContentLayout
        header={profile && <ManageProfileHeader profile={profile} />}
        headerClassName={styles.header}
        content={
          !isLoadingProfile &&
          !profileError && (
            <ManageProfileContent account={account} profile={profile} />
          )
        }
        contentClassName={archivedClass}
      />
      <LoadingOverlay
        animateOnEnter={false}
        isOpen={isLoadingProfile}
        text="Please Wait"
      />
    </>
  );
};
