import {
  Button,
  ButtonTypes,
  ButtonVariants,
  EditIcon,
  ForwardIcon,
  IEntitlement,
  IError,
  IProfile,
  LoadingOverlay,
  Vendors,
  formatDate,
  renderVendorNameFromEntitlement,
  useToast,
  TrashCan,
} from '@comptia-sso/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormikProvider, useFormik } from 'formik';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, useMemo, useState } from 'react';

// Components.
import { Card, Detail, EditableCalendar, EditableDetail } from 'components';

// Providers..
import { EditableProvider } from 'providers';

// Hooks.
import {
  useUpdateProfileEntitlements,
  useDeactivateProfileEntitlement,
} from 'hooks';

// Styles.
import styles from './EntitlementCard.module.scss';

// Types.
import { IAccount } from 'types';
import { useRedirectToVendor } from 'hooks/vendor/useRedirectToVendor';

interface IEntitlementCardProps {
  account?: IAccount;
  entitlement: IEntitlement;
  profile?: IProfile;
}

export const EntitlementCard = (props: IEntitlementCardProps): ReactElement => {
  const { account, entitlement, profile } = props;
  const [popToast] = useToast();
  const [redirectToVendor] = useRedirectToVendor(profile?.id);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [updateEntitlement] = useUpdateProfileEntitlements(
    profile?.id,
    entitlement?.accessKey,
  );
  const [deactivateEntitlement] = useDeactivateProfileEntitlement(
    profile?.id,
    entitlement?.accessKey,
  );
  const canEdit = useMemo(
    () =>
      (account?.permissions?.editEntitlements &&
        entitlement?.vendor !== Vendors.CertMasterPractice &&
        entitlement?.accessKey) ??
      false,
    [account, entitlement],
  );

  const canGoToVendor = () => {
    return entitlement.vendor === Vendors.Skillable;
  };

  const canDeactivateUserEntitlement = () => {
    return entitlement.vendor === Vendors.Populi;
  };

  const deactivateUserEntitlement = async () => {
    setIsDeactivating(true);
    try {
      await deactivateEntitlement();
      setIsDeactivating(false);
      popToast('Populi Entitlement successfully deactivated.');
    } catch (error) {
      setIsDeactivating(false);
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  };

  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const id = useMemo(() => uniqueId(), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: entitlement?.comments ?? '',
      expirationDate: entitlement?.expirationDate ?? '',
    },
    onSubmit: async (values) => {
      if (account?.permissions?.editEntitlements) {
        try {
          await updateEntitlement(values);
          setIsEditing(false);
          popToast('Entitlement successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
  });

  return (
    <EditableProvider isEditing={isEditing}>
      <Card collapsed onClick={toggleExpanded}>
        <div className={styles.preview}>
          <Detail
            emptyMessage="-"
            label="Access Key"
            value={entitlement?.accessKey}
          />
          <Detail
            emptyMessage="-"
            label="Product Title"
            value={entitlement?.product?.title}
          />
          <Detail
            emptyMessage="-"
            label="Vendor"
            value={renderVendorNameFromEntitlement(entitlement)}
          />
          <button
            aria-controls={`entitlement-${id}-additional`}
            aria-label={
              isExpanded ? 'Collapse Entitlement' : 'Expand Entitlement'
            }
            aria-expanded={isExpanded}
            className={styles.toggle}
            onClick={toggleExpanded}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </button>
        </div>
        {isExpanded && (
          <form
            id={`entitlement-${id}-additional`}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <FormikProvider value={formik}>
              <div
                className={styles.content}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={styles.grid}>
                  <div className={styles.column}>
                    <Detail
                      emptyMessage="-"
                      label="Product Code"
                      value={entitlement?.productCode}
                    />
                    <Detail
                      emptyMessage="-"
                      label="Product Vendor Code"
                      value={entitlement?.product?.vendorProductCode}
                    />
                    <Detail
                      emptyMessage="-"
                      label="Product Audience"
                      value={entitlement?.product?.audience}
                    />
                    <Detail
                      emptyMessage="-"
                      label="Last Updated Date"
                      value={formatDate(
                        entitlement?.trackingData?.modified?.date,
                      )}
                    />
                  </div>
                  <div className={styles.column}>
                    <Detail
                      emptyMessage="-"
                      label="Redemption Date"
                      value={formatDate(entitlement?.redemptionDate)}
                    />
                    {entitlement?.vendor !== Vendors.Populi && (
                      <EditableCalendar
                        emptyMessage="-"
                        label="Expiration Date"
                        name="expirationDate"
                        value={formatDate(entitlement?.expirationDate)}
                      />
                    )}
                    {entitlement?.vendor === Vendors.Populi && (
                      <Detail
                        label="Expiration Date"
                        value={formatDate(entitlement?.expirationDate)}
                      />
                    )}
                    <Detail
                      label="Status"
                      value={
                        !entitlement?.expirationDate
                          ? '-'
                          : new Date(entitlement?.expirationDate) > new Date()
                          ? 'Active'
                          : 'Expired'
                      }
                    />
                    <Detail
                      emptyMessage="-"
                      label="Last Updated By"
                      value={[
                        entitlement?.trackingData?.modified?.firstName,
                        entitlement?.trackingData?.modified?.lastName,
                      ]
                        .join(' ')
                        .trim()}
                    />
                  </div>
                  <div className={styles.column}>
                    <EditableDetail
                      emptyMessage="-"
                      label="Comments"
                      name="comments"
                      textarea
                      value={entitlement?.comments}
                    />
                  </div>
                  {canEdit && (
                    <div>
                      <button
                        className={styles.edit}
                        onClick={() => setIsEditing(true)}
                        type="button"
                      >
                        <EditIcon />
                      </button>
                      {canGoToVendor() && (
                        <button
                          className={styles.edit}
                          onClick={() => redirectToVendor(entitlement.vendor)}
                          type="button"
                        >
                          <ForwardIcon />
                        </button>
                      )}
                      {canDeactivateUserEntitlement() &&
                        (new Date(entitlement?.expirationDate) > new Date() ? (
                          <button
                            className={styles.edit}
                            onClick={deactivateUserEntitlement}
                            type="button"
                            title="Deactivate Populi Entitlement"
                            disabled={
                              new Date(entitlement?.expirationDate) <=
                              new Date()
                            }
                          >
                            <TrashCan />
                          </button>
                        ) : (
                          <button
                            className={styles.edit + ' ' + styles.faded}
                            type="button"
                            title="Populi Entitlement has already been decativated or expired"
                            disabled
                          >
                            <TrashCan />
                          </button>
                        ))}
                    </div>
                  )}
                </div>
                {canEdit && isEditing && (
                  <div className={styles.buttons}>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        setIsEditing(false);
                      }}
                      variant={ButtonVariants.Secondary}
                    >
                      Cancel
                    </Button>
                    <Button type={ButtonTypes.Submit}>Save</Button>
                  </div>
                )}
              </div>
            </FormikProvider>
          </form>
        )}
        <LoadingOverlay
          isOpen={formik.isSubmitting || isDeactivating}
          text={
            formik.isSubmitting
              ? 'Updating Entitlement'
              : 'Deactivating Entitlement'
          }
        />
      </Card>
    </EditableProvider>
  );
};
