/* eslint-disable @typescript-eslint/no-explicit-any */

export enum PatchOps {
  Add = 'add',
  Replace = 'replace',
}

export interface IPatchRequestObj {
  op: PatchOps;
  path: string;
  value: any;
}

export type IPatchRequest = IPatchRequestObj[];
