import {
  Button,
  IProfile,
  IModalProps,
  IUserActivity,
  Modal,
  ModalVariants,
  formatDate,
} from '@comptia-sso/core';
import { ReactElement } from 'react';
import HighlightOff from '@material-ui/icons/HighlightOff';

// Styles.
import styles from './ActivityModal.module.scss';

interface IActivityModal extends IModalProps {
  profile?: IProfile;
  onClose?: VoidFunction;
  userActivities?: IUserActivity[];
}

export const ActivityModal = (props: IActivityModal): ReactElement => {
  const { open, profile, onClose, userActivities, ...modalProps } = props;

  if (!profile) return <></>;

  return (
    <>
      <Modal
        {...modalProps}
        className={styles.modal}
        disableClose
        disableHeader
        open={open}
        onClose={onClose}
        variant={ModalVariants.Large}
      >
        <div className={styles.modalContainer}>
          <Button className={styles.closeButton} onClick={onClose}>
            <HighlightOff />
          </Button>
          <h3 className={styles.header}>User Activity</h3>
          <h5>Login History</h5>
          <ul className={styles.list}>
            {userActivities?.map((event, index) => {
              return (
                <li className={styles.item} key={index}>
                  <div className={styles.itemContainer}>
                    <div className={styles.date}>
                      {formatDate(event.loginDate)}
                    </div>
                    <div className={styles.itemContent}>
                      <span className={styles.itemHeader}>{event.ip}</span>
                    </div>
                    <div className={styles.itemContent}>
                      <span className={styles.itemHeader}>{event.status}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    </>
  );
};
