import {
  Button,
  ButtonTypes,
  ButtonVariants,
  IError,
  IProfile,
  LoadingOverlay,
  useToast,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';
import AddCircle from '@material-ui/icons/AddCircle';

// Components.
import {
  Card,
  EditableCalendar,
  EditableContent,
  EditableDetail,
} from 'components';

// Hooks.
import { useAddProfileSuspension } from 'hooks';

// Styles.
import styles from './AddSuspension.module.scss';

// Types.
import { IAccount } from 'types';

// Validations.
import { validationSchema } from './validationSchema';

interface IAddSuspensionProps {
  account: IAccount;
  profile: IProfile;
}

export const AddSuspension = (props: IAddSuspensionProps): ReactElement => {
  const { account, profile } = props;
  const [popToast] = useToast();
  const [addSuspension] = useAddProfileSuspension(profile?.id);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      comments: '',
      endDate: new Date().toISOString(),
      startDate: new Date().toISOString(),
    },
    onSubmit: async (values, { resetForm }) => {
      if (account?.permissions?.changeSuspensions) {
        try {
          await addSuspension(values);
          resetForm();
          toggleisAdding();
          popToast('Suspension added successfully.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema: validationSchema(),
  });

  const toggleisAdding = () => setIsAdding(!isAdding);

  if (!profile) return <></>;

  if (!account.permissions?.changeSuspensions) return <></>;

  if (!isAdding)
    return (
      <>
        <div className={styles.addSuspension}>
          <AddCircle
            onClick={toggleisAdding}
            className={styles.addCircle}
            data-testid="AddSuspension"
          />
        </div>
      </>
    );

  return (
    <>
      <EditableContent
        canEdit={account?.permissions?.editAnnouncements ?? false}
        isEditing
        formik={formik}
        headerClassName={styles.hidden}
      >
        <Card className={styles.content}>
          <h2>Add Suspension</h2>
          <div className={styles.grid}>
            <div className={styles.column}>
              <EditableCalendar
                emptyMessage=""
                label="Start Date"
                name="startDate"
              />
            </div>
            <div className={styles.column}>
              <EditableCalendar
                emptyMessage=""
                label="End Date"
                name="endDate"
              />
            </div>
            <div className={styles.column}>
              <EditableDetail
                emptyMessage=""
                label="Comments"
                name="comments"
                textarea
              />
            </div>
          </div>
          {isAdding && (
            <div className={styles.buttons}>
              <Button
                onClick={() => {
                  formik.resetForm();
                  toggleisAdding();
                }}
                variant={ButtonVariants.Secondary}
              >
                Cancel
              </Button>
              <Button type={ButtonTypes.Submit}>Save</Button>
            </div>
          )}
        </Card>
      </EditableContent>
      <LoadingOverlay
        isOpen={!formik.isValidating && formik.isSubmitting}
        text="Adding Suspension"
      />
    </>
  );
};
