import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

// Styles.
import styles from './ContentLayout.module.scss';

interface IMainLayoutProps {
  content?: ReactNode;
  contentClassName?: string;
  header?: ReactNode;
  headerClassName?: string;
}

export const ContentLayout = (props: IMainLayoutProps): ReactElement => {
  const { content, contentClassName, header, headerClassName } = props;

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, headerClassName)}>{header}</div>
      <div className={classNames(styles.content, contentClassName)}>
        {content}
      </div>
    </div>
  );
};
