import { IProfile } from '@comptia-sso/core';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  continuationToken: null,
  hasSearched: false,
  pageSize: 10,
  request: {
    city: '',
    country: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    searchField: 'Name',
    searchString: '',
    state: '',
  },
  results: [] as IProfile[],
  selectedResults: [] as IProfile[],
};

const slice = createSlice({
  initialState,
  name: 'profileSearch',
  reducers: {
    addSelectedResult: (state, action) => {
      state.selectedResults.push(action.payload);
    },
    initializeSearch: (state, action) => {
      const resetRequest = action.payload;

      state.continuationToken = null;
      state.hasSearched = false;
      state.results = [];

      if (resetRequest) {
        state.request = initialState.request;
      }
    },
    removeSelectedResult: (state, action) => {
      state.selectedResults = state.selectedResults.filter(
        (profile) => profile.id !== action.payload.id,
      );
    },
    resetSearch: (state) => {
      state.continuationToken = null;
      state.hasSearched = false;
      state.request = initialState.request;
    },
    resetSelectedResults: (state) => {
      state.selectedResults = [];
    },
    setRequest: (state, action) => {
      state.request = action.payload;
    },
    setResponse: (state, action) => {
      const { continuationToken, profiles } = action.payload;

      state.continuationToken = continuationToken;
      state.results = state.hasSearched
        ? [...state.results, ...profiles]
        : profiles;

      if (!state.hasSearched) state.hasSearched = true;
    },
  },
});

export const profileSearchActions = slice.actions;
export const profileSearchReducer = slice.reducer;
