import { IEntitlement } from '@comptia-sso/core';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  continuationToken: null,
  hasSearched: false,
  pageSize: 10,
  request: {
    accessKey: '',
    productTitle: '',
    vendor: '',
  },
  results: [] as IEntitlement[],
  selectedResults: [] as IEntitlement[],
};

const slice = createSlice({
  initialState,
  name: 'accessKeySearch',
  reducers: {
    addSelectedResult: (state, action) => {
      state.selectedResults.push(action.payload);
    },
    initializeSearch: (state, action) => {
      const resetRequest = action.payload;

      state.continuationToken = null;
      state.hasSearched = false;
      state.results = [];

      if (resetRequest) {
        state.request = initialState.request;
      }
    },
    removeSelectedResult: (state, action) => {
      state.selectedResults = state.selectedResults.filter(
        (profile) => profile.id !== action.payload.id,
      );
    },
    setRequest: (state, action) => {
      state.request = action.payload;
    },
    setResponse: (state, action) => {
      const { continuationToken, accessKeys } = action.payload;

      state.continuationToken = continuationToken;
      state.results = state.hasSearched
        ? [...state.results, ...accessKeys]
        : accessKeys;

      if (!state.hasSearched) state.hasSearched = true;
    },
  },
});

export const accessKeySearchActions = slice.actions;
export const accessKeySearchReducer = slice.reducer;
