import { IProfile, yup } from '@comptia-sso/core';

export const getValidationSchema = (profile: IProfile): yup.SchemaOf<unknown> =>
  yup.object().shape({
    email: yup
      .string()
      .required('Required')
      .isValidEmail()
      .test('current-email', 'Cannot be your current email', function (value) {
        return value !== profile?.email;
      })
      .test(
        'alternate-email',
        'Cannot be your alternate email',
        function (value) {
          return value !== profile?.alternateEmail;
        },
      ),
  });
