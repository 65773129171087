import { ReactElement } from 'react';

// Components.
import {
  Button,
  ButtonVariants,
  IModalProps,
  Modal,
  ModalVariants,
} from '@comptia-sso/core';

// Styles.
import styles from './ConfirmationModal.module.scss';

export interface IConfirmationModalProps extends IModalProps {
  confirmationMessage: string;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}

export const ConfirmationModal = (
  props: IConfirmationModalProps,
): ReactElement => {
  const { confirmationMessage, onConfirm, onCancel, ...modalProps } = props;

  return (
    <Modal
      {...modalProps}
      disableClose
      disableHeader
      onClose={onCancel}
      variant={ModalVariants.Small}
    >
      <p className={styles.content}>{confirmationMessage}</p>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          onClick={onCancel}
          variant={ButtonVariants.Secondary}
        >
          No
        </Button>
        <Button className={styles.button} onClick={onConfirm}>
          Yes
        </Button>
      </div>
    </Modal>
  );
};
