import {
  Button,
  ButtonTypes,
  ButtonVariants,
  EditIcon,
  ISuspension,
  IError,
  IProfile,
  LoadingOverlay,
  formatDate,
  useToast,
} from '@comptia-sso/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormikProvider, useFormik } from 'formik';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, useMemo, useState } from 'react';

// Components.
import { Card, Detail, EditableCalendar, EditableDetail } from 'components';

// Providers..
import { EditableProvider } from 'providers';

// Hooks.
import { useUpdateProfileSuspensions } from 'hooks';

// Styles.
import styles from './SuspensionCard.module.scss';

// Types.
import { IAccount } from 'types';
import { validationSchema } from './validationSchema';

interface ISuspensionCardProps {
  account?: IAccount;
  suspension: ISuspension;
  profile?: IProfile;
}

export const SuspensionCard = (props: ISuspensionCardProps): ReactElement => {
  const { account, suspension, profile } = props;
  const [popToast] = useToast();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [updateSuspension] = useUpdateProfileSuspensions(
    profile?.id,
    suspension?.id,
  );
  const canEdit = useMemo(
    () => account?.permissions?.changeSuspensions ?? false,
    [account, suspension],
  );

  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const id = useMemo(() => uniqueId(), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: suspension?.comments ?? '',
      createDate: suspension?.createDate ?? '',
      endDate: suspension?.endDate ?? '',
      id: suspension?.id ?? '',
      startDate: suspension?.startDate ?? '',
    },
    onSubmit: async (values) => {
      if (account?.permissions?.changeSuspensions) {
        try {
          await updateSuspension(values);
          setIsEditing(false);
          popToast('Suspension successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema: validationSchema(),
  });

  return (
    <EditableProvider isEditing={isEditing}>
      <Card collapsed onClick={toggleExpanded}>
        <div className={styles.preview}>
          <Detail emptyMessage="-" label="Id" value={suspension?.id} />
          <Detail
            emptyMessage="-"
            label="Date Created"
            value={formatDate(suspension?.createDate)}
          />
          <Detail emptyMessage="" label="" value="" />
          <button
            aria-controls={`suspension-${id}-additional`}
            aria-label={
              isExpanded ? 'Collapse Suspension' : 'Expand Suspension'
            }
            aria-expanded={isExpanded}
            className={styles.toggle}
            onClick={toggleExpanded}
          >
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </button>
        </div>
        {isExpanded && (
          <form
            id={`suspension-${id}-additional`}
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <FormikProvider value={formik}>
              <div
                className={styles.content}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={styles.grid}>
                  <div className={styles.column}>
                    <EditableCalendar
                      emptyMessage="-"
                      label="Start Date"
                      name="startDate"
                      value={formatDate(suspension?.startDate)}
                    />
                  </div>
                  <div className={styles.column}>
                    <EditableCalendar
                      emptyMessage="-"
                      label="End Date"
                      name="endDate"
                      value={formatDate(suspension?.endDate)}
                    />
                  </div>
                  <div className={styles.column}>
                    <EditableDetail
                      emptyMessage="-"
                      label="Comments"
                      name="comments"
                      textarea
                      value={suspension?.comments}
                    />
                  </div>
                  {canEdit && (
                    <div>
                      <button
                        className={styles.edit}
                        onClick={() => setIsEditing(true)}
                        type="button"
                      >
                        <EditIcon />
                      </button>
                    </div>
                  )}
                </div>
                {canEdit && isEditing && (
                  <div className={styles.buttons}>
                    <Button
                      onClick={() => {
                        formik.resetForm();
                        setIsEditing(false);
                      }}
                      variant={ButtonVariants.Secondary}
                    >
                      Cancel
                    </Button>
                    <Button type={ButtonTypes.Submit}>Save</Button>
                  </div>
                )}
              </div>
            </FormikProvider>
          </form>
        )}
        <LoadingOverlay
          isOpen={formik.isSubmitting}
          text="Updating Suspension"
        />
      </Card>
    </EditableProvider>
  );
};
