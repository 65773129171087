import { Button, IProfile } from '@comptia-sso/core';
import { MouseEvent, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

// Components.
import { ProfileSearchResult } from './ProfileSearchResult';

// Hooks.
import { useAccount, useAppSelector } from 'hooks';

// Styles.
import styles from './UserSearchResults.module.scss';

// Store.
import { profileSearchActions } from 'store/slices';

const { addSelectedResult, removeSelectedResult } = profileSearchActions;

interface IProfileSearchResultsProps {
  continuationToken: string | null;
  hasSearched: boolean;
  profiles: IProfile[];
  onLoadMore(): void;
}

export const ProfileSearchResults = (
  props: IProfileSearchResultsProps,
): ReactElement => {
  const { continuationToken, hasSearched, onLoadMore, profiles } = props;
  const dispatch = useDispatch();
  const selectedResults = useAppSelector(
    (state) => state.profileSearch.selectedResults,
  );
  const [account] = useAccount();

  const handleMergeClick = (e: MouseEvent) => {
    if (selectedResults.length !== 2) {
      e.preventDefault();
    }
  };

  const handleAddProfile = (profile: IProfile) =>
    dispatch(addSelectedResult(profile));

  const handleRemoveProfile = (profile: IProfile) =>
    dispatch(removeSelectedResult(profile));

  const handleSelectProfile = (profile: IProfile, isSelected: boolean) => {
    if (isSelected) {
      handleAddProfile(profile);
    } else {
      handleRemoveProfile(profile);
    }
  };

  return (
    <>
      {account?.permissions?.mergeProfile && selectedResults.length > 0 && (
        <div className={styles.userMergeQueue}>
          <h6>Merge Queue</h6>
          <div className={styles.searchResults}>
            {selectedResults?.map((profile) => (
              <ProfileSearchResult
                isMergeQueue
                key={profile?.id}
                onRemove={handleRemoveProfile}
                profile={profile}
              />
            ))}
            <Button
              className={styles.button}
              disabled={selectedResults.length !== 2}
              link
              onClick={handleMergeClick}
              to={`/profiles/merge?id1=${selectedResults[0]?.id}&id2=${selectedResults[1]?.id}`}
            >
              Merge Records
            </Button>
          </div>
        </div>
      )}

      {(hasSearched || profiles.length > 0) && (
        <div className={styles.userSearchResults}>
          <h2>Search Results ({profiles?.length})</h2>
          <p>Select two profiles to merge the results.</p>

          <div className={styles.searchResults}>
            {profiles?.map((profile) => {
              const isChecked = selectedResults.some(
                (selectedProfile) => selectedProfile.id === profile.id,
              );
              const isArchived = profile?.archived ?? false;
              const isDisabled =
                isArchived ||
                ((account?.permissions?.mergeProfile ?? false) &&
                  !isChecked &&
                  selectedResults.length > 1);

              return (
                <ProfileSearchResult
                  isChecked={isChecked}
                  isDisabled={isDisabled}
                  key={profile.id}
                  onSelect={handleSelectProfile}
                  profile={profile}
                />
              );
            })}
            {profiles?.length > 0 && continuationToken && (
              <Button className={styles.button} onClick={onLoadMore}>
                Load More
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
