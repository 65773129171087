import { IError, IProfile, Spinner, useToast } from '@comptia-sso/core';
import chunk from 'lodash/chunk';
import { ReactElement, useEffect, useMemo, useState } from 'react';

// Components.
import { Card, Pagination } from 'components';
import { ZendeskTicketCard } from './ZendeskTicketCard';

// Hooks.
import { useProfileZendeskTickets } from 'hooks';

// Styles.
import styles from './ViewZendeskTickets.module.scss';

interface IViewZendeskTickets {
  profile?: IProfile;
}

const totalPerPage = 10;

export const ViewZendeskTickets = (
  props: IViewZendeskTickets,
): ReactElement => {
  const { profile } = props;
  const [popToast] = useToast();
  const [
    zendeskTickets,
    { error: zendeskTicketsError, isLoading: isLoadingZendeskTickets },
  ] = useProfileZendeskTickets(profile?.id);
  const [page, setPage] = useState(1);

  const pageCount = useMemo(
    () => Math.ceil((zendeskTickets?.length ?? 0) / totalPerPage),
    [zendeskTickets],
  );

  const zendeskTicketsForPage = useMemo(
    () => chunk(zendeskTickets, totalPerPage)[page - 1],
    [page, zendeskTickets],
  );

  useEffect(() => {
    if (zendeskTicketsError) {
      popToast(
        (zendeskTicketsError as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [zendeskTicketsError]);

  return (
    <section title="Zendesk Tickets">
      <header>
        <h2>Zendesk Tickets</h2>
      </header>
      {profile && !isLoadingZendeskTickets ? (
        <div className={styles.cards}>
          {(zendeskTickets?.length ?? 0) > 0 ? (
            <>
              {zendeskTicketsForPage.map((ticket, index) => (
                <ZendeskTicketCard
                  zendeskTicket={ticket}
                  key={ticket?.id || index}
                />
              ))}
              {pageCount > 1 && (
                <Pagination
                  onPageChange={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              )}
            </>
          ) : (
            <Card className={styles['no-entitlements']}>
              This profile has no Zendesk tickets.
            </Card>
          )}
        </div>
      ) : (
        <Card>
          <Spinner className={styles.spinner} />
        </Card>
      )}
    </section>
  );
};
