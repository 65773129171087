import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getAccounts } from 'services';

// Types.
import { IAccount } from 'types';

type UseAccounts = [IAccount[] | undefined, IQueryHookOptions];

export const useAccounts = (): UseAccounts => {
  const { data, error, isError, isLoading } = useQuery<IAccount[], IError>(
    ['accounts'],
    () => getAccounts(),
  );
  return [data, { error, isError, isLoading }];
};
