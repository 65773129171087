import {
  Button,
  IProfile,
  IModalProps,
  IUserLoggingAnalytics,
  Modal,
  ModalVariants,
} from '@comptia-sso/core';
import { ReactElement } from 'react';
import HighlightOff from '@material-ui/icons/HighlightOff';

// Components
import { EventLogItem } from './EventLogItem';

// Styles.
import styles from './EventLogModal.module.scss';

interface IEventLogModal extends IModalProps {
  open: boolean;
  profile?: IProfile;
  onClose?: VoidFunction;
  userLoggingAnalytics?: IUserLoggingAnalytics[];
}

export const EventLogModal = (props: IEventLogModal): ReactElement => {
  const { open, profile, onClose, userLoggingAnalytics, ...modalProps } = props;

  if (!profile) return <></>;

  return (
    <>
      <Modal
        {...modalProps}
        className={styles.modal}
        disableClose
        disableHeader
        onClose={onClose}
        open={open}
        variant={ModalVariants.Large}
      >
        <div className={styles.modalContainer}>
          <Button className={styles.closeButton} onClick={onClose}>
            <HighlightOff />
          </Button>
          <h3 className={styles.header}>Event Log</h3>
          <ul className={styles.list}>
            {userLoggingAnalytics?.map((event, index) => {
              return (
                <li className={styles.item} key={index}>
                  <EventLogItem event={event} />
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    </>
  );
};
