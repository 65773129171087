import {
  Button,
  ButtonVariants,
  IEntitlement,
  IError,
  LoadingOverlay,
  useToast,
} from '@comptia-sso/core';
import { ReactElement, useState } from 'react';

// Components.
import { AccessKeyDetails } from './AccessKeyDetails';
import { AccessKeyComments } from './AccessKeyComments';
import { AccessKeyRedemptions } from './AccessKeyRedemptions';

// Hooks.
import { useUpdateAccessKey } from 'hooks';

// Styles.
import styles from './AccessKeyContent.module.scss';

// Types.
import { IAccount } from 'types';

interface IAccessKeyContentProps {
  accessKey: IEntitlement;
  account?: IAccount;
}

export const AccessKeyContent = (
  props: IAccessKeyContentProps,
): ReactElement => {
  const { accessKey, account } = props;
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateAccessKey] = useUpdateAccessKey(accessKey.id);

  const handleActivate = async () => {
    try {
      setIsActivating(true);
      await updateAccessKey({ active: true });
      popToast('Access key successfully activated.');
    } catch (error) {
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
    setIsActivating(false);
  };

  const handleDeactivate = async () => {
    try {
      setIsDeactivating(true);
      await updateAccessKey({ active: false });
      popToast('Access key successfully deactivated.');
    } catch (error) {
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
    setIsDeactivating(false);
  };

  return (
    <div className={styles.content}>
      <AccessKeyDetails accessKey={accessKey} account={account} />
      <AccessKeyRedemptions redemptions={accessKey.redemptions} />
      <AccessKeyComments accessKey={accessKey} account={account} />
      {account?.permissions?.editAccessKeys && (
        <>
          {accessKey.active ? (
            <Button
              className={styles.deactivate}
              disabled={accessKey?.redemptions?.length > 0}
              onClick={handleDeactivate}
              variant={ButtonVariants.Secondary}
            >
              Deactivate Access Key
            </Button>
          ) : (
            <Button
              className={styles.activate}
              disabled={accessKey?.redemptions?.length > 0}
              onClick={handleActivate}
              variant={ButtonVariants.Secondary}
            >
              Activate Access Key
            </Button>
          )}
        </>
      )}
      <LoadingOverlay isOpen={isActivating} text="Activating Access Key" />
      <LoadingOverlay isOpen={isDeactivating} text="Deactivating Access Key" />
    </div>
  );
};
