import { ReactElement } from 'react';

// Components.
import { AnnouncementDetails } from '../../components/AnnouncementDetails';
import { AnnouncementAddHeader } from './AnnouncementAddHeader';

// Hooks.
import { useAccount } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';
import { IAnnouncement } from '@comptia-sso/core';

export const AnnouncementAddView = (): ReactElement => {
  const [account] = useAccount();
  const announcement = {
    endDate: new Date().toUTCString(),
    language: 'en',
    sortOrder: 0,
    startDate: new Date().toISOString(),
  } as unknown as IAnnouncement;

  return (
    <>
      <ContentLayout
        header={<AnnouncementAddHeader />}
        content={
          <AnnouncementDetails
            account={account}
            mode="add"
            announcement={announcement}
          />
        }
      />
    </>
  );
};
