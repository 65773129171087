import { Vendors } from '@comptia-sso/core';
import { useCallback, useState } from 'react';

// Hooks.
import { useRedirectToSkillable } from './useRedirectToSkillable';

interface VendorRedirectOptions {
  classroom?: string;
  productCode?: string;
}

type RedirectToVendorFunction = (
  vendor: Vendors,
  options?: VendorRedirectOptions,
) => void;

export const useRedirectToVendor = (
  profileId = '',
): [RedirectToVendorFunction, { isLoading: boolean }] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const redirectToSkillable = useRedirectToSkillable(profileId);

  const callback = useCallback(
    async (vendor: Vendors) => {
      setIsLoading(true);
      try {
        switch (vendor?.toLowerCase()) {
          case Vendors.Skillable.toLowerCase(): {
            await redirectToSkillable();
            break;
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error; // To be handled upstream.
      }
    },
    [redirectToSkillable],
  );

  return [callback, { isLoading }];
};
