import {
  ISuspension,
  IError,
  IQueryHookOptions,
  IUpdateAnnouncementRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { addProfileSuspension } from 'services';

type AddProfileSuspensionFunction = UseMutateAsyncFunction<
  ISuspension,
  IError,
  IUpdateAnnouncementRequest
>;

type UseAddProfileSuspension = [
  AddProfileSuspensionFunction,
  IQueryHookOptions,
];

export const useAddProfileSuspension = (
  profileId = '',
): UseAddProfileSuspension => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    ISuspension,
    IError,
    IUpdateAnnouncementRequest
  >(
    (values: IUpdateAnnouncementRequest) =>
      addProfileSuspension(profileId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles', profileId, 'suspensions']);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
