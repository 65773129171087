import { FormTimeField, ITextFieldProps } from '@comptia-sso/core';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';

// Components.
import { Detail } from 'components';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './EditableTime.module.scss';

type IEditableTimeProps = ITextFieldProps & {
  containerClassName?: string;
  emptyMessage?: string;
  label: string;
  name: string;
};

export const EditableTime = (props: IEditableTimeProps): ReactElement => {
  const { label, name, containerClassName, emptyMessage, ...textFieldProps } =
    props;
  const { isEditing } = useEditableContext();
  const { isSubmitting } = useFormikContext();
  const [field] = useField(name);

  const getTime = (dateTime: Date) => {
    let hour = dateTime.getUTCHours();
    const minute = dateTime.getUTCMinutes();
    let amPm = 'AM';
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
      amPm = 'PM';
    }
    return hour
      .toString()
      .concat(':', minute < 10 ? '0' : '', minute.toString(), ' ', amPm);
  };

  return (
    <div className={classNames(styles.detail, containerClassName)}>
      {isEditing ? (
        <div>
          <FormTimeField
            {...textFieldProps}
            disabled={props.disabled || isSubmitting}
            name={name}
            label={label}
          />
        </div>
      ) : (
        <Detail
          emptyMessage={emptyMessage}
          label={label}
          value={getTime(new Date(field.value))}
        />
      )}
    </div>
  );
};
