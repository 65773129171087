import { LoadingOverlay } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Components.
import { Header } from './Header';
import { Nav } from './Nav';

// Styles.
import styles from './App.module.scss';

// Hooks.
import { useAccount } from 'hooks';

// Views.
import {
  AccessKeyEditView,
  AccessKeySearchView,
  AnnouncementListView,
  AuthorizationView,
  ProfileEditView,
  ProfileMergeView,
  ProfileSearchView,
} from 'views';
import { AnnouncementAddView } from 'views/AnnouncementAddView';
import { AnnouncementEditView } from 'views/AnnouncementEditView';

export const App = (): ReactElement => {
  const location = useLocation();
  const [account, { isLoading: isLoadingAccount }] = useAccount();

  // Scroll page to top when route changes.
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <>
      <div className={styles.container}>
        <Header />
        <div className={styles.columns}>
          <aside className={styles.sidebar}>
            {!isLoadingAccount && <Nav />}
          </aside>
          <main className={styles.main}>
            {!isLoadingAccount && (
              <Routes>
                <Route element={<ProfileSearchView />} index />
                <Route
                  element={
                    account?.permissions?.mergeProfile ? (
                      <ProfileMergeView />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                  path="profiles/merge"
                />
                <Route element={<ProfileEditView />} path="profiles/:id" />
                <Route path="access-keys">
                  <Route element={<AccessKeySearchView />} index />
                  <Route element={<AccessKeyEditView />} path=":id" />
                </Route>
                <Route path="announcements">
                  <Route element={<AnnouncementListView />} index />
                  <Route element={<AnnouncementAddView />} path="add" />
                  <Route element={<AnnouncementEditView />} path=":id" />
                </Route>
                <Route element={<AuthorizationView />} path="authorization" />
                <Route element={<Navigate to="/" />} path="*" />
              </Routes>
            )}
          </main>
        </div>
      </div>
      <LoadingOverlay isOpen={isLoadingAccount} text="Please Wait" />
    </>
  );
};
