import { IAnnouncement } from '@comptia-sso/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Styles.
import styles from './AnnouncementItem.module.scss';

interface IAnnouncementResultProps {
  announcement: IAnnouncement;
}

export const AnnouncementItem = (
  props: IAnnouncementResultProps,
): ReactElement => {
  const { announcement } = props;

  function formatDate(dateVal: string) {
    const newDate = new Date(dateVal);

    const sMonth = padValue(newDate.getUTCMonth() + 1);
    const sDay = padValue(newDate.getUTCDate());
    const sYear = newDate.getUTCFullYear();
    let sHour = newDate.getUTCHours();
    const sMinute = padValue(newDate.getUTCMinutes());
    let sAMPM = 'AM';

    if (sHour > 12) {
      sAMPM = 'PM';
      sHour = sHour - 12;
    } else if (sHour === 0) {
      sHour = 12;
    }

    return (
      sMonth +
      '-' +
      sDay +
      '-' +
      sYear +
      ' ' +
      padValue(sHour) +
      ':' +
      sMinute +
      ' ' +
      sAMPM
    );
  }

  function padValue(value: number) {
    return value < 10 ? '0' + value : value;
  }

  return (
    <Link
      className={styles.container}
      to={`/announcements/${announcement?.id}`}
    >
      <div className={styles.column}>
        <div className={styles.item}>
          <strong>Heading</strong>
          <span>{announcement.heading}</span>
        </div>
        <div className={styles.item}>
          <strong>Message</strong>
          <span>{announcement.message}</span>
        </div>
        <div className={styles.item}>
          <strong>Language</strong>
          <span>{announcement.language}</span>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.item}>
          <strong>Start Date</strong>
          <span>{formatDate(announcement.startDate)}</span>
        </div>
        <div className={styles.item}>
          <strong>End Date</strong>
          <span>{formatDate(announcement.endDate)}</span>
        </div>
        <div className={styles.item}>
          <strong>Sort Order</strong>
          <span>{announcement.sortOrder}</span>
        </div>
      </div>
      <div className={styles.link}>
        <ArrowForwardIos />
      </div>
    </Link>
  );
};
