import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Hooks.
import { useB2CAccount } from 'hooks';

// Services.
import { getAccount } from 'services';

// Types.
import { IAccount } from 'types';

type UseAccount = [IAccount, IQueryHookOptions];

export const useAccount = (): UseAccount => {
  const [B2CAccount] = useB2CAccount();
  const {
    data = {} as IAccount,
    error,
    isError,
    isLoading,
  } = useQuery<IAccount, IError>(
    ['account'],
    () => getAccount(B2CAccount?.idTokenClaims?.sub as string),
    {
      enabled: !!B2CAccount?.idTokenClaims?.sub,
    },
  );
  return [data, { error, isError, isLoading }];
};
