import {
  Button,
  ButtonVariants,
  IProfile,
  getGivenNameFromProfile,
  getPreferredNameFromProfile,
  ButtonTypes,
  useToast,
  IError,
  LoadingOverlay,
} from '@comptia-sso/core';
import { PersonOutlineRounded } from '@material-ui/icons';
import { usePostToPearson } from 'hooks/admin/usePostToPearson';
import { useRedirectToPearson } from 'hooks/vendor/useRedirectToPearson';
import { ReactElement, useState } from 'react';

// Styles.
import styles from './ManageProfileHeader.module.scss';

interface IManageProfileHeaderProps {
  profile: IProfile;
}

export const ManageProfileHeader = (
  props: IManageProfileHeaderProps,
): ReactElement => {
  const { profile } = props;
  const [popToast] = useToast();
  const [postToPearson] = usePostToPearson(profile?.id);
  const redirectToPearson = useRedirectToPearson();
  const [isPostingToPearson, setIsPostingToPearson] = useState(false);

  const postUserToPearson = async () => {
    try {
      setIsPostingToPearson(true);
      await postToPearson();
      await redirectToPearson(profile);
    } catch (error) {
      setIsPostingToPearson(false);
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  };

  return (
    <header>
      <div className={styles.title}>
        <h1>Edit User Profile</h1>
      </div>
      <div className={styles.breadcrumb}>
        <h2 className={styles.subheading}>
          <PersonOutlineRounded className={styles.icon} />
          Looking for another account?
        </h2>
        <Button
          className={styles.back}
          link
          to="/"
          variant={ButtonVariants.Secondary}
        >
          Back to Search
        </Button>
      </div>
      <div className={styles.postToPearsonRow}>
        <>
          {profile.archived ? (
            <span className={styles.archived}>Archived</span>
          ) : profile.isBlocked ? (
            <span className={styles.archived}>Exam Registration Blocked</span>
          ) : !profile.externalIds?.pearsonVue ? (
            <span className={styles.archived}>No Pearson VUE ID</span>
          ) : (
            <Button
              className={styles.button}
              type={ButtonTypes.Button}
              onClick={postUserToPearson}
            >
              Post to Pearson
            </Button>
          )}
        </>
      </div>
      <div className={styles['preview-container']}>
        <div className={styles.preview}>
          <div className={styles.item}>
            <strong className={styles.label}>Name</strong>
            <span className={styles.value}>
              {getGivenNameFromProfile(profile)}
            </span>
          </div>
          <div className={styles.item}>
            <strong className={styles.label}>Preferred Name</strong>
            <span className={styles.value}>
              {getPreferredNameFromProfile(profile)}
            </span>
          </div>
          <div className={styles.item}>
            <strong className={styles.label}>Candidate ID</strong>
            <span className={styles.value}>{profile?.compTIAId}</span>
          </div>
          <div className={styles.item}>
            <strong className={styles.label}>Email Address</strong>
            <span className={styles.value}>{profile?.email}</span>
          </div>
        </div>
      </div>
      <LoadingOverlay
        isOpen={isPostingToPearson}
        text="Posting to PearsonVue"
      />
    </header>
  );
};
