/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  ButtonTypes,
  ButtonVariants,
  EditDisabledIcon,
  EditIcon,
} from '@comptia-sso/core';
import classNames from 'classnames';
import { FormikContextType, FormikProvider } from 'formik';
import { PropsWithChildren, ReactElement } from 'react';

// Providers.
import { EditableProvider } from 'providers';

// Styles.
import styles from './EditableContent.module.scss';

interface IEditableContentProps {
  buttonContainerClassName?: string;
  canEdit?: boolean;
  className?: string;
  formik: FormikContextType<any>;
  headerClassName?: string;
  isArchived?: boolean;
  isEditing?: boolean;
  onCancel?: VoidFunction;
  onEdit?: VoidFunction;
  title?: string;
  visible?: boolean;
}

export const EditableContent = (
  props: PropsWithChildren<IEditableContentProps>,
): ReactElement => {
  const {
    buttonContainerClassName,
    canEdit = true,
    children,
    className,
    isEditing = false,
    formik,
    headerClassName,
    isArchived,
    onCancel,
    onEdit,
    title,
  } = props;

  return (
    <EditableProvider isEditing={isEditing}>
      <FormikProvider value={formik}>
        <section className={className}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <header
              className={classNames(
                styles.header,
                { [styles.hasTitle]: !!title },
                headerClassName,
              )}
            >
              {title && <h2>{title}</h2>}
              <div
                className={classNames(styles.buttons, buttonContainerClassName)}
              >
                {isEditing ? (
                  <>
                    <Button
                      className={styles.button}
                      disabled={formik.isSubmitting}
                      data-testid="cancel-button"
                      onClick={() => {
                        formik.resetForm();
                        onCancel?.();
                      }}
                      variant={ButtonVariants.Secondary}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={styles.button}
                      data-testid="save-button"
                      key="save-button"
                      type={ButtonTypes.Submit}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    {isArchived ? (
                      <span className={styles.archived}>Archived</span>
                    ) : (
                      <button
                        aria-label="Edit"
                        className={styles.edit}
                        disabled={!canEdit}
                        data-testid="edit-button"
                        key="edit-button"
                        onClick={onEdit}
                        type="button"
                      >
                        {canEdit ? <EditIcon /> : <EditDisabledIcon />}
                      </button>
                    )}
                  </>
                )}
              </div>
            </header>
            {children}
          </form>
        </section>
      </FormikProvider>
    </EditableProvider>
  );
};
