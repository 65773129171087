import {
  IEntitlement,
  IProfile,
  ISuspension,
  IUpdateProfileSuspensionRequest,
  IUpdateEntitlementRequest,
  IUpdateProfileEntitlementRequest,
  IUpdateProfileRequest,
  IUserActivity,
  IUserLoggingAnalytics,
  constructEntitlementPatchRequest,
  constructProfilePatchRequest,
  IUpdateComptiaIdandPearsonVueId,
  IZendeskTicket,
  IAnnouncement,
  IUpdateAnnouncementRequest,
} from '@comptia-sso/core';

// Config.
import { env } from 'config';

// Services.
import { getRequest, patchRequest, postRequest, putRequest } from './api';

// Types.
import {
  IAccessKeySearchRequest,
  IAccessKeySearchResponse,
  IAccount,
  IProfileSearchRequest,
  IProfileSearchResponse,
  IProfileSelectMergeRecordResponse,
  IProfileSelectMergeRecordRequest,
  ISetEmailRequest,
  ISetPasswordRequest,
  IPearsonTokens,
} from 'types';

export const addAnnouncement = (
  values: IUpdateAnnouncementRequest,
): Promise<IAnnouncement> =>
  postRequest<IAnnouncement>(
    `${env.ADMIN_API_HOST}/api/announcements`,
    values,
    env.ADMIN_API_KEY,
  );

export const addProfileSuspension = (
  profileId: string,
  values: IUpdateProfileSuspensionRequest,
): Promise<ISuspension> =>
  postRequest<ISuspension>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/suspensions`,
    values,
    env.ADMIN_API_KEY,
  );

export const deactivateProfileEntitlement = (
  profileId: string,
  accessKey: string,
): Promise<IEntitlement> => {
  return putRequest<IEntitlement>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/entitlements/${accessKey}/deactivate`,
    '{}',
    env.ADMIN_API_KEY,
  );
};

export const getAccessKey = (accessKey: string): Promise<IEntitlement> =>
  getRequest<IEntitlement>(
    `${env.ADMIN_API_HOST}/api/accesskeys/${accessKey}`,
    env.ADMIN_API_KEY,
  );

export const getAccount = (id: string): Promise<IAccount> =>
  getRequest<IAccount>(
    `${env.ADMIN_API_HOST}/api/accounts/${id}`,
    env.ADMIN_API_KEY,
  );

export const getAccounts = (): Promise<IAccount[]> =>
  getRequest<IAccount[]>(
    `${env.ADMIN_API_HOST}/api/accounts`,
    env.ADMIN_API_KEY,
  );

export const getAnnouncement = (id: string): Promise<IAnnouncement> =>
  getRequest<IAnnouncement>(
    `${env.ADMIN_API_HOST}/api/announcements/${id}`,
    env.ADMIN_API_KEY,
  );

export const getAnnouncements = (): Promise<IAnnouncement[]> =>
  getRequest<IAnnouncement[]>(
    `${env.ADMIN_API_HOST}/api/announcements`,
    env.ADMIN_API_KEY,
  );

export const getProfile = (profileId: string): Promise<IProfile> =>
  getRequest<IProfile>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}`,
    env.ADMIN_API_KEY,
  );

export const getProfileEntitlements = (
  profileId: string,
): Promise<IEntitlement[]> =>
  getRequest<IEntitlement[]>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/entitlements`,
    env.ADMIN_API_KEY,
  );

export const getProfileSuspensions = (
  profileId: string,
): Promise<ISuspension[]> =>
  getRequest<ISuspension[]>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/suspensions`,
    env.ADMIN_API_KEY,
  );

export const getProfileZendeskTickets = (
  profileId: string,
): Promise<IZendeskTicket[]> =>
  getRequest<IZendeskTicket[]>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/zendesktickets`,
    env.ADMIN_API_KEY,
  );

export const getUserActivity = (profileId: string): Promise<IUserActivity[]> =>
  getRequest<IUserActivity[]>(
    `${env.ADMIN_API_HOST}/api/accounts/${profileId}/login-history`,
    env.ADMIN_API_KEY,
  );

export const getUserLoggingAnalytics = (
  profileId: string,
): Promise<IUserLoggingAnalytics[]> =>
  getRequest<IUserLoggingAnalytics[]>(
    `${env.ADMIN_API_HOST}/api/accounts/logs/${profileId}`,
    env.ADMIN_API_KEY,
  );

export const mergeRecords = (
  values: IProfileSelectMergeRecordRequest,
): Promise<IProfileSelectMergeRecordResponse> => {
  return postRequest<IProfileSelectMergeRecordResponse>(
    `${env.ADMIN_API_HOST}/api/merge`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const postToPearson = (profileId: string): Promise<IProfile> => {
  return postRequest<IProfile>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/pearson`,
    '',
    env.ADMIN_API_KEY,
  );
};

export const searchAccessKeys = (
  values: IAccessKeySearchRequest,
): Promise<IAccessKeySearchResponse> => {
  return postRequest<IAccessKeySearchResponse>(
    `${env.ADMIN_API_HOST}/api/accesskeys/search`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const searchProfiles = (
  values: IProfileSearchRequest,
): Promise<IProfileSearchResponse> => {
  return postRequest<IProfileSearchResponse>(
    `${env.ADMIN_API_HOST}/api/profiles/search`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const setEmail = (
  profileId: string,
  values: ISetEmailRequest,
): Promise<void> => {
  return postRequest<void, ISetEmailRequest>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/set-email`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const setPassword = (values: ISetPasswordRequest): Promise<void> => {
  return postRequest<void, ISetPasswordRequest>(
    `${env.ADMIN_API_HOST}/api/profiles/set-password`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const updateAccessKey = (
  accessKey: string,
  values: IUpdateEntitlementRequest,
): Promise<IEntitlement> =>
  patchRequest<IEntitlement>(
    `${env.ADMIN_API_HOST}/api/accesskeys/${accessKey}`,
    constructEntitlementPatchRequest(values),
    env.ADMIN_API_KEY,
  );

export const updateAccounts = (
  accountId: string,
  values: Record<string, boolean>,
): Promise<IAccount> =>
  putRequest<IAccount, Record<string, boolean>>(
    `${env.ADMIN_API_HOST}/api/accounts/permissions/${accountId}`,
    values,
    env.ADMIN_API_KEY,
  );

export const updateAnnouncement = (
  announcementId: string,
  values: IUpdateAnnouncementRequest,
): Promise<IAnnouncement> =>
  putRequest<IAnnouncement>(
    `${env.ADMIN_API_HOST}/api/announcements/${announcementId}`,
    values,
    env.ADMIN_API_KEY,
  );

export const updateProfile = (
  profileId: string,
  values: IUpdateProfileRequest,
): Promise<IProfile> => {
  return patchRequest<IProfile>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}`,
    constructProfilePatchRequest(values),
    env.ADMIN_API_KEY,
  );
};

export const updateProfileEntitlement = (
  profileId: string,
  accessKey: string,
  values: IUpdateProfileEntitlementRequest,
): Promise<IEntitlement> => {
  return putRequest<IEntitlement>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/entitlements/${accessKey}`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const updateProfileSuspension = (
  profileId: string,
  suspensionId: string,
  values: IUpdateProfileSuspensionRequest,
): Promise<ISuspension> => {
  return putRequest<ISuspension>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/suspensions/${suspensionId}`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const updateComptiaIdAndPearsonVueId = (
  profileId: string,
  values: IUpdateComptiaIdandPearsonVueId,
): Promise<IProfile> => {
  return patchRequest<IProfile>(
    `${env.ADMIN_API_HOST}/api/profiles/${profileId}/updatecomptiaidandpearsonvueid`,
    values,
    env.ADMIN_API_KEY,
  );
};

export const getPearsonTokens = (id: string): Promise<IPearsonTokens> =>
  getRequest<IPearsonTokens>(
    `${env.ADMIN_API_HOST}/api/profiles/${id}/pearson/tokens/`,
    env.ADMIN_API_KEY,
  );

export const getSkillableSSOUrl = (id: string): Promise<{ url: string }> =>
  getRequest(
    `${env.ADMIN_API_HOST}/api/profiles/${id}/skillable/ssosurl/`,
    env.ADMIN_API_KEY,
  );
