import { Button, ButtonVariants } from '@comptia-sso/core';
import { ReactElement } from 'react';

// Styles.
import styles from './AnnouncementHeader.module.scss';

export const AnnouncementHeader = (): ReactElement => {
  return (
    <header className={styles.header}>
      <div className={styles.title}>
        <h1>Edit Announcement</h1>
      </div>
      <div className={styles.breadcrumb}>
        <Button
          className={styles.back}
          link
          to="/announcements"
          variant={ButtonVariants.Secondary}
        >
          Back to Announcements List
        </Button>
      </div>
    </header>
  );
};
