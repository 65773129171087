import {
  Button,
  ButtonTypes,
  FormTextField,
  IError,
  IProfile,
  LoadingOverlay,
  useToast,
} from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import { Card, ConfirmationModal } from 'components';

// Hooks.
import { useSetEmail } from 'hooks';

// Styles.
import styles from './ChangeEmail.module.scss';

// Types.
import { IAccount } from 'types';

// Validations.
import { getValidationSchema } from './validationSchema';

interface IChangeEmailProps {
  account: IAccount;
  profile: IProfile;
}

export const ChangeEmail = (props: IChangeEmailProps): ReactElement => {
  const { account, profile } = props;
  const [isConfirming, setIsConfirming] = useState(false);
  const [popToast] = useToast();
  const [setEmail] = useSetEmail(profile?.id);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (account?.permissions?.changeEmail) {
        if (!isConfirming) {
          setIsConfirming(true);
        } else {
          setIsConfirming(false);

          try {
            await setEmail(values);
            resetForm();
            popToast('Email changed successfully.');
          } catch (error) {
            popToast(
              (error as IError)?.message ||
                'An unexpected error occurred. Please try again later.',
            );
          }
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(profile),
  });

  const handleConfirm = () => formik.submitForm();
  const handleCancel = () => setIsConfirming(false);

  if (!profile) return <></>;

  if (profile?.authenticationSource === 'socialIdpAuthentication') {
    return (
      <Card className={styles.social}>
        <span className={styles.text}>
          This is a social account. The email cannot be changed.
        </span>
      </Card>
    );
  }

  return (
    <>
      <Card className={styles.content}>
        <h3>User Email</h3>
        <form noValidate onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <FormTextField
              className={styles.input}
              disabled={profile?.archived}
              label="Email"
              name="email"
              required
            />
            <Button
              className={styles.button}
              disabled={profile?.archived}
              type={ButtonTypes.Submit}
            >
              Set Email
            </Button>
          </FormikProvider>
        </form>
      </Card>
      <ConfirmationModal
        confirmationMessage="Are you sure you want to change this email?"
        open={isConfirming}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <LoadingOverlay
        isOpen={!formik.isValidating && formik.isSubmitting}
        text="Changing Email"
      />
    </>
  );
};
