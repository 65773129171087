import { Configuration } from '@azure/msal-browser';

// Config.
import { env } from './env';

export const msalConfig: Configuration = {
  auth: {
    authority: `https://${env.B2C_DOMAIN}/${env.B2C_TENANT}/${env.B2C_POLICY}`,
    clientId: env.B2C_CLIENT_ID,
    knownAuthorities: [env.B2C_DOMAIN],
    redirectUri: window.location.origin,
  },
};

export const msalRequest = { scopes: ['openid', env.B2C_CLIENT_ID] };
