import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import keys from 'lodash/keys';
import { ReactElement, useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormik } from 'formik';

// Components.
import { Card, EditableContent } from 'components';
import { PermissionsMatrix } from '../PermissionsMatrix';

// Hooks.
import { useUpdateAccounts } from 'hooks';

// Styles.
import styles from './AccountData.module.scss';

// Types.
import { IAccount } from 'types';

interface IAccountData {
  account: IAccount;
  currentAccount?: IAccount;
}

export const AccountData = (props: IAccountData): ReactElement => {
  const { account, currentAccount } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [updateAccounts] = useUpdateAccounts(account?.id);
  const [popToast] = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: account?.permissions,
    onSubmit: async (values) => {
      if (currentAccount?.permissions?.userManagementChangePermissions) {
        try {
          await updateAccounts(values);
          setIsEditing(false);
          popToast('Permissions successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message || 'An unexpected error occurred.',
          );
        }
      } else {
        popToast('You do not have permission to perform that action.');
      }
    },
  });

  return (
    <>
      <Card className={styles.account} collapsed onClick={() => setOpen(!open)}>
        <div className={styles.accountContainer}>
          <div className={styles.item}>
            <strong>Name</strong>
            <span>{account?.displayName}</span>
          </div>
          <div className={styles.item} style={{ flexGrow: 2 }}>
            <strong>Email Address</strong>
            <span>{account?.email}</span>
          </div>
          <div className={styles.item}>
            <strong>Permissions</strong>
            <span>{`${account?.permissionsCount}/${
              keys(account?.permissions).length
            }`}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.viewbuttoncontainer}>
              <span
                className={styles.viewbutton}
                data-testid={`account-data-${account?.id}`}
                aria-label="View All Account Data"
              >
                {open ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </span>
            </div>
          </div>
        </div>
        {open && (
          <div onClick={(e) => e.stopPropagation()}>
            <EditableContent
              buttonContainerClassName={styles.buttons}
              canEdit={
                currentAccount?.permissions?.userManagementChangePermissions ??
                false
              }
              className={styles.editable}
              formik={formik}
              headerClassName={styles.header}
              isEditing={isEditing}
              onCancel={() => {
                formik.resetForm();
                setIsEditing(false);
              }}
              onEdit={() => setIsEditing(true)}
            >
              <PermissionsMatrix open={open} />
            </EditableContent>
          </div>
        )}
      </Card>
      <LoadingOverlay
        isOpen={formik.isSubmitting}
        text="Updating Permissions"
      />
    </>
  );
};
