import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { searchAccessKeys } from 'services';

// Types.
import { IAccessKeySearchRequest, IAccessKeySearchResponse } from 'types';

type SearchAccessKeysFunction = UseMutateAsyncFunction<
  IAccessKeySearchResponse,
  IError,
  IAccessKeySearchRequest
>;

type UseSearchProfile = [SearchAccessKeysFunction, IQueryHookOptions];

export const useSearchAccessKeys = (): UseSearchProfile => {
  const { error, mutateAsync, isError, isLoading } = useMutation<
    IAccessKeySearchResponse,
    IError,
    IAccessKeySearchRequest
  >((values: IAccessKeySearchRequest) => searchAccessKeys(values));

  return [mutateAsync, { error, isError, isLoading }];
};
