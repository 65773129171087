import { yup } from '@comptia-sso/core';

export const validationSchema = yup.object().shape({
  primaryAddress: yup.object().shape({
    address1: yup.string().required('Required').isValidAddressLine1(),
    address2: yup.string().isValidAddressLine2(),
    address3: yup.string().isValidAddressLine3(),
    city: yup.string().required('Required').isValidCity(),
    country: yup.string().required('Required').isValidCountry(),
    postalCode: yup
      .string()
      .isValidPostalCode()
      .when('country', {
        is: (value: string) => ['CAN', 'USA'].includes(value),
        then: yup.string().required('Required'),
      }),
    state: yup
      .string()
      .isValidState()
      .when('country', {
        is: (value: string) => ['CAN', 'USA'].includes(value),
        then: yup.string().required('Required'),
      }),
  }),
});
