import classNames from 'classnames';
import { ReactElement } from 'react';

// Styles.
import styles from './AccountInitials.module.scss';

// Types
import { IB2CAccount } from 'types';

interface IAccountInitialsProps {
  B2CAccount: IB2CAccount;
  className?: string;
}

export const AccountInitials = (props: IAccountInitialsProps): ReactElement => {
  const { className, B2CAccount } = props;

  return (
    <div className={classNames(styles.initials, className)}>
      {[
        B2CAccount.idTokenClaims?.given_name?.[0],
        B2CAccount.idTokenClaims?.family_name?.[0],
      ]
        .filter(Boolean)
        .join('')}
    </div>
  );
};
