/* eslint-disable @typescript-eslint/no-explicit-any */
import { Calendar, ICalendarProps, formatUTCDate } from '@comptia-sso/core';
import { useField, useFormikContext } from 'formik';
import { useCallback, ReactElement } from 'react';

// Components.
import { Detail } from 'components';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './EditableCalendar.module.scss';

type IEditableCalendarProps = ICalendarProps & {
  emptyMessage?: string;
  label: string;
  name: string;
};

export const EditableCalendar = (
  props: IEditableCalendarProps,
): ReactElement => {
  const { emptyMessage, label, name, ...calendarProps } = props;
  const { isEditing } = useEditableContext();
  const { isSubmitting, setFieldValue } = useFormikContext<any>();
  const [field, fieldMeta] = useField(name);

  const handleChange = useCallback(
    (value) => {
      setFieldValue(name, value);
    },
    [name],
  );

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <Detail
          emptyMessage={emptyMessage}
          label={label}
          value={formatUTCDate(field.value)}
        />
        {isEditing && (
          <Calendar
            {...calendarProps}
            disabled={isSubmitting}
            onChange={handleChange}
            value={field.value}
          />
        )}
      </div>
      {fieldMeta.error && <div className={styles.error}>{fieldMeta.error}</div>}
    </div>
  );
};
