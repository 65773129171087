import { IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateAccounts } from 'services';

// Types.
import { IAccount } from 'types';

type UpdateProfileEntitlementFunction = UseMutateAsyncFunction<
  IAccount,
  IError,
  Record<string, boolean>
>;

type UseUpdateProfileEntitlement = [
  UpdateProfileEntitlementFunction,
  IQueryHookOptions,
];

export const useUpdateAccounts = (
  accountId = '',
): UseUpdateProfileEntitlement => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IAccount,
    IError,
    Record<string, boolean>
  >((values: Record<string, boolean>) => updateAccounts(accountId, values), {
    onSuccess: (data) => {
      if (data) {
        const currentAccount = queryClient.getQueryData<IAccount>(['account']);
        if (data.id === currentAccount?.id) {
          queryClient.setQueryData(['account'], data);
        }

        const newAccounts = queryClient
          .getQueryData<IAccount[]>(['accounts'])
          ?.reduce<IAccount[]>((acc, account) => {
            if (account.id === data.id) {
              acc.push(data);
            } else {
              acc.push(account);
            }
            return acc;
          }, []);

        queryClient.setQueryData(['accounts'], newAccounts);
      }
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
