import { yup } from '@comptia-sso/core';

export const validationSchema = (): yup.SchemaOf<unknown> =>
  yup.object().shape({
    limitPerUse: yup.number().required('Required'),
    product: yup.object().shape({
      vendorProductCode: yup.string().required('Required'),
    }),
    productCode: yup.string().required('Required'),
    vendor: yup.string().required('Required'),
  });
