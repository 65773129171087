import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components.
import { AnnouncementDetails } from '../../components/AnnouncementDetails';
import { AnnouncementHeader } from './AnnouncementHeader';

// Hooks.
import { useAnnouncement, useAccount } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

export const AnnouncementEditView = (): ReactElement => {
  const { id } = useParams();
  const [account] = useAccount();
  const [popToast] = useToast();
  const [announcement, { error, isLoading }] = useAnnouncement(id);

  useEffect(() => {
    if (error) {
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [error]);

  return (
    <>
      <ContentLayout
        header={<AnnouncementHeader />}
        content={
          !isLoading &&
          !error && (
            <AnnouncementDetails
              announcement={announcement}
              account={account}
              mode="edit"
            />
          )
        }
      />
      <LoadingOverlay isOpen={isLoading} text="Please Wait" />
    </>
  );
};
