import { IEntitlement, IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getProfileEntitlements } from 'services';

type UseProfileEntitlements = [IEntitlement[], IQueryHookOptions];

export const useProfileEntitlements = (
  profileId = '',
): UseProfileEntitlements => {
  const {
    data = [] as IEntitlement[],
    error,
    isError,
    isLoading,
  } = useQuery<IEntitlement[], IError>(
    ['profiles', profileId, 'entitlements'],
    () => getProfileEntitlements(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
