import {
  FormTextArea,
  IEntitlement,
  IError,
  LoadingOverlay,
  useToast,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import { EditableContent } from 'components';

// Hooks.
import { useUpdateAccessKey } from 'hooks';

// Types.
import { IAccount } from 'types';

interface IAccessKeyCommentsProps {
  accessKey: IEntitlement;
  account?: IAccount;
}

export const AccessKeyComments = (
  props: IAccessKeyCommentsProps,
): ReactElement => {
  const { accessKey, account } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateAccessKey] = useUpdateAccessKey(accessKey.id);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: accessKey?.comments ?? '',
    },
    onSubmit: async (values) => {
      if (account?.permissions?.editAccessKeys) {
        try {
          await updateAccessKey(values);
          setIsEditing(false);
          popToast('Access key comments successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
  });

  return (
    <section>
      <EditableContent
        canEdit={
          (account?.permissions?.editAccessKeys &&
            accessKey?.redemptions?.length === 0) ??
          false
        }
        formik={formik}
        isEditing={isEditing}
        onCancel={() => {
          formik.resetForm();
          setIsEditing(false);
        }}
        onEdit={() => setIsEditing(true)}
        title=""
      >
        <FormTextArea disabled={!isEditing} label="Comments" name="comments" />
      </EditableContent>
      <LoadingOverlay
        isOpen={formik.isSubmitting}
        text="Updating Access Key Comments"
      />
    </section>
  );
};
