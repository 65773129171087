import classNames from 'classnames';
import { ReactElement, PropsWithChildren } from 'react';

// Styles.
import styles from './Card.module.scss';

interface ICardProps {
  className?: string;
  collapsed?: boolean;
  onClick?: VoidFunction;
}

export const Card = (props: PropsWithChildren<ICardProps>): ReactElement => {
  const { children, className, collapsed, onClick } = props;

  return (
    <div
      className={classNames(
        styles.card,
        { [styles.collapsed]: collapsed },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
