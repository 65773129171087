import { ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { AccountInitials } from 'components';

// Hooks.
import { useAuthLogout, useB2CAccount } from 'hooks';

// Images.
import comptiaLogo from 'images/comptia-logo.svg';

// Styles.
import styles from './Header.module.scss';

export const Header = (): ReactElement => {
  const [B2CAccount] = useB2CAccount();
  const logout = useAuthLogout();

  const handleLogout = useCallback(
    (e) => {
      e.preventDefault();
      logout();
    },
    [logout],
  );

  return (
    <header className={styles.header}>
      <Link className={styles.logo} to="/">
        <img alt="CompTIA Logo" src={comptiaLogo} />
      </Link>
      {B2CAccount && (
        <ul className={styles.utility}>
          <li>
            <AccountInitials B2CAccount={B2CAccount} />
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              Logout
            </a>
          </li>
        </ul>
      )}
    </header>
  );
};
