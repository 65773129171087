import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useEffect, useMemo } from 'react';

// Components.
import { SearchFormTextField } from 'components';
import { AccountData } from './AccountData';

// Hooks.
import { useAccount, useAccounts } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

export const AuthorizationView = (): ReactElement => {
  const [popToast] = useToast();
  const [currentAccount] = useAccount();
  const [accounts, { error, isLoading }] = useAccounts();

  const formik = useFormik({
    initialValues: {
      filter: '',
    },
    onSubmit: () => {
      /*
        We're not submitting and only using formik to set the filter value
        because <SearchFormTextField /> uses formik under the hood.
      */
    },
  });

  const filteredAccounts = useMemo(
    () =>
      accounts?.filter(
        (account) =>
          account.displayName
            .toLowerCase()
            .includes(formik.values.filter.toLowerCase()) ||
          account.email
            .toLowerCase()
            .includes(formik.values.filter.toLowerCase()),
      ),
    [accounts, formik.values.filter],
  );
  const sortedAccounts = useMemo(
    () =>
      filteredAccounts
        ?.slice()
        .sort((a, b): number =>
          a.displayName
            .toLowerCase()
            .localeCompare(b.displayName.toLowerCase()),
        ),
    [filteredAccounts],
  );

  useEffect(() => {
    if (error) {
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [error]);

  return (
    <>
      <ContentLayout
        header={
          <div>
            <h1>Administration Authorization</h1>
            <FormikProvider value={formik}>
              <SearchFormTextField
                label="Display Name or Email Address"
                name="filter"
              />
            </FormikProvider>
          </div>
        }
        content={
          <div>
            {sortedAccounts?.map((account) => (
              <AccountData
                account={account}
                currentAccount={currentAccount}
                key={account.id}
              />
            ))}
          </div>
        }
      />
      <LoadingOverlay isOpen={isLoading} text="Loading Accounts" />
    </>
  );
};
