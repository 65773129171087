import { FormEventHandler, ReactElement } from 'react';
import {
  Button,
  ButtonTypes,
  ButtonVariants,
  FormTextField,
  getStatesByCountry,
  getCountries,
} from '@comptia-sso/core';
import classNames from 'classnames';

// Components.
import { SearchFormTextField } from 'components';

// Styles.
import styles from './SearchUserProfiles.module.scss';

interface IProfileSearchFormProps {
  hasSearched: boolean;
  isDirty: boolean;
  onClear(): void;
  onSearch: FormEventHandler<HTMLFormElement>;
  searchField: string;
}

const states = getStatesByCountry('USA');
const countries = getCountries();

export const ProfileSearchForm = (
  props: IProfileSearchFormProps,
): ReactElement => {
  const { hasSearched, isDirty, onClear, onSearch, searchField } = props;

  return (
    <div className={styles.searchUserProfiles}>
      <div className={styles.searchUserProfilesHeader}>
        <h1>Search User Profiles</h1>
      </div>

      <div className={styles.searchUserProfilesFields}>
        <form aria-label="search profiles" noValidate onSubmit={onSearch}>
          <div className={styles.searchTop}>
            <div className={styles.col}>
              {searchField === 'Name' ? (
                <div
                  className={classNames(styles.gridFields, styles.largeInput)}
                >
                  <SearchFormTextField name="firstName" label="First Name" />
                  <SearchFormTextField name="lastName" label="Last Name" />
                </div>
              ) : (
                <div className={styles.largeInput}>
                  <SearchFormTextField
                    label="Search"
                    name="searchString"
                    required
                  />
                </div>
              )}
            </div>
            <div className={styles.col}>
              <SearchFormTextField
                label="Select Search Criteria"
                name="searchField"
                required
                select
              >
                <option value="Name">First and Last Name</option>
                <option value="CompTIAId">CompTIA ID</option>
                <option value="Email">Email Address</option>
              </SearchFormTextField>
            </div>
          </div>
          <div className={styles.searchBottom}>
            <div className={classNames(styles.col, styles.gridFields)}>
              <FormTextField
                className={styles.searchCity}
                label="City"
                name="city"
              />
              <FormTextField
                className={styles.searchState}
                label="State"
                name="state"
                select
              >
                {states?.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.abbreviation}
                  </option>
                ))}
              </FormTextField>
              <FormTextField
                className={styles.searchCountry}
                label="Country"
                name="country"
                select
              >
                {countries?.map((country) => (
                  <option
                    key={country.abbreviation}
                    value={country.abbreviation}
                  >
                    {country.name}
                  </option>
                ))}
              </FormTextField>
              <FormTextField
                className={styles.searchZip}
                label="Postal Code"
                name="postalCode"
              />
            </div>
            <div className={(styles.col, styles.buttons)}>
              <Button
                className={styles.button}
                disabled={!hasSearched && !isDirty}
                key="search"
                type={ButtonTypes.Submit}
              >
                Search
              </Button>
              {(hasSearched || isDirty) && (
                <Button
                  className={classNames(styles.button, styles.buttonSecondary)}
                  variant={ButtonVariants.Secondary}
                  onClick={onClear}
                  key="clear"
                >
                  Clear Search
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
