import {
  IEntitlement,
  IError,
  IQueryHookOptions,
  IUpdateEntitlementRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateAccessKey } from 'services';

type UpdateProfileEntitlementFunction = UseMutateAsyncFunction<
  IEntitlement,
  unknown,
  IUpdateEntitlementRequest
>;

type UseUpdateProfileEntitlement = [
  UpdateProfileEntitlementFunction,
  IQueryHookOptions,
];

export const useUpdateAccessKey = (
  entitlementId = '',
): UseUpdateProfileEntitlement => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IEntitlement,
    IError,
    IUpdateEntitlementRequest
  >(
    (values: IUpdateEntitlementRequest) =>
      updateAccessKey(entitlementId, values),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['entitlements', entitlementId], data);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
