import { IProfile, yup } from '@comptia-sso/core';

export const getValidationSchema = (
  profile: IProfile,
  changeProfileName = false,
  isPhoneRequired = false,
): yup.SchemaOf<unknown> =>
  yup.object().shape({
    alternateEmail: yup
      .string()
      .isValidEmail()
      .test('emails-match', 'Must not match primary email', function (value) {
        return profile.email !== value;
      }),
    company: yup.string().isValidCompany(),
    ...(changeProfileName
      ? {
          givenName: yup.object().shape({
            firstName: yup
              .string()
              .required('Required')
              .isValidGivenFirstName()
              .isNameNotAnEmail(),
            lastName: yup
              .string()
              .required('Required')
              .isValidGivenLastName()
              .isNameNotAnEmail(),
            middleName: yup
              .string()
              .isValidGivenMiddleName()
              .isNameNotAnEmail(),
            suffix: yup.string().isValidGivenSuffix(),
          }),
        }
      : {}),
    mobilePhoneNumber: yup.object().shape({
      countryCode: yup.string().isValidPhoneNumberCountryCode(),
    }),
    preferredName: yup.object().shape({
      firstName: yup.string().isValidPreferredFirstName().isNameNotAnEmail(),
      lastName: yup.string().isValidPreferredLastName().isNameNotAnEmail(),
      middleName: yup.string().isValidPreferredMiddleName().isNameNotAnEmail(),
    }),
    primaryPhoneNumber: yup.object().shape({
      countryCode: isPhoneRequired
        ? yup.string().required('Required').isValidPhoneNumberCountryCode()
        : yup.string().isValidPhoneNumberCountryCode(),
      phone: isPhoneRequired
        ? yup.string().required('Required').isValidPhoneNumber()
        : yup.string().isValidPhoneNumber(),
    }),
    secondaryPhoneNumber: yup.object().shape({
      countryCode: yup
        .string()
        .isValidPhoneNumberCountryCode()
        .test(
          'phone-matches',
          'Must not match primary phone number',
          function (value) {
            // eslint-disable-next-line
            // @ts-ignore
            const [, rootSchema] = this.from;
            const { primaryPhoneNumber, secondaryPhoneNumber } =
              rootSchema?.value || {};

            if (!primaryPhoneNumber.phone) return true;

            return (
              primaryPhoneNumber.countryCode !== value ||
              primaryPhoneNumber.phone !== secondaryPhoneNumber.phone
            );
          },
        ),
      phone: yup
        .string()
        .isValidPhoneNumber()
        .test(
          'phone-matches',
          'Must not match primary phone number',
          function (value) {
            // eslint-disable-next-line
            // @ts-ignore
            const [, rootSchema] = this.from;
            const { primaryPhoneNumber, secondaryPhoneNumber } =
              rootSchema?.value || {};

            if (!primaryPhoneNumber.phone) return true;

            return (
              primaryPhoneNumber.countryCode !==
                secondaryPhoneNumber.countryCode ||
              primaryPhoneNumber.phone !== value
            );
          },
        ),
    }),
  });
