import {
  IAnnouncement,
  IError,
  IQueryHookOptions,
  IUpdateAnnouncementRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { addAnnouncement, updateAnnouncement } from 'services';

type UpdateAnnouncementFunction = UseMutateAsyncFunction<
  IAnnouncement,
  unknown,
  IUpdateAnnouncementRequest
>;

type UseUpdateAnnouncement = [UpdateAnnouncementFunction, IQueryHookOptions];

export const useUpdateAnnouncement = (
  announcementId = '',
): UseUpdateAnnouncement => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IAnnouncement,
    IError,
    IUpdateAnnouncementRequest
  >(
    (values: IUpdateAnnouncementRequest) => {
      if (announcementId === '') {
        return addAnnouncement(values);
      }
      return updateAnnouncement(announcementId, values);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['announcement', announcementId], data);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
