import { yup } from '@comptia-sso/core';

export const validationSchema = (): yup.SchemaOf<unknown> =>
  yup.object().shape({
    endDate: yup
      .date()
      .required('Required')
      .test(
        'endDateShouldBeAfterStartDate',
        'End date should be after start date',
        function () {
          const startTime = new Date(this.parent.startTime);
          const startDateTime = new Date(
            new Date(this.parent.startDate).setUTCHours(
              startTime.getUTCHours(),
              startTime.getUTCMinutes(),
            ),
          );
          const endTime = new Date(this.parent.endTime);
          const endDateTime = new Date(
            new Date(this.parent.endDate).setUTCHours(
              endTime.getUTCHours(),
              endTime.getUTCMinutes(),
            ),
          );

          return startDateTime < endDateTime;
        },
      ),
    endTime: yup.string().required('Required'),
    language: yup.string().required('Required'),
    message: yup.string().required('Required'),
    startDate: yup.date().required('Required'),
    startTime: yup.date().required('Required'),
  });
