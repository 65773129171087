import {
  IError,
  IProfile,
  LoadingOverlay,
  countries,
  getPrimaryAddressFromProfile,
  getStatesByCountry,
  useToast,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useMemo, useState } from 'react';

// Components.
import { Card, EditableContent, EditableDetail } from 'components';

// Hooks.
import { useUpdateProfile } from 'hooks';

// Styles.
import styles from './ManageUserAddress.module.scss';

// Types.
import { IAccount } from 'types';

// Validation.
import { validationSchema } from './validationSchema';

interface IManageUserAddressProps {
  account?: IAccount;
  profile?: IProfile;
}

export const ManageUserAddress = (
  props: IManageUserAddressProps,
): ReactElement => {
  const { account, profile } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateProfile] = useUpdateProfile(profile?.id);
  const primaryAddress = useMemo(
    () => getPrimaryAddressFromProfile(profile),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      primaryAddress: {
        address1: primaryAddress?.address1 || '',
        address2: primaryAddress?.address2 || '',
        address3: primaryAddress?.address3 || '',
        city: primaryAddress?.city || '',
        country: primaryAddress?.country || 'USA',
        index: primaryAddress?.index,
        postalCode: primaryAddress?.postalCode || '',
        state: primaryAddress?.state || '',
      },
    },
    onSubmit: async (values) => {
      if (account?.permissions?.editProfile) {
        try {
          await updateProfile(values);
          setIsEditing(false);
          popToast('Address successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  const country = formik.values.primaryAddress?.country;
  const states = useMemo(() => getStatesByCountry(country), [country]);

  const handleCountryChange = () => {
    formik.setFieldValue('primaryAddress.state', '');
  };

  return (
    <>
      <EditableContent
        canEdit={account?.permissions?.editProfile ?? false}
        isArchived={profile?.archived ?? false}
        isEditing={isEditing}
        onCancel={() => {
          formik.resetForm();
          setIsEditing(false);
        }}
        onEdit={() => setIsEditing(true)}
        formik={formik}
        title="User Address"
      >
        <Card className={styles.container}>
          <div className={styles.columns}>
            <div className={styles.column}>
              <EditableDetail
                label="Country"
                name="primaryAddress.country"
                onChange={handleCountryChange}
                renderValue={(value) =>
                  countries?.find((country) => country.abbreviation === value)
                    ?.name ?? ''
                }
                required
                select
              >
                {countries?.map((country) => (
                  <option
                    key={country.abbreviation}
                    value={country.abbreviation}
                  >
                    {country.name}
                  </option>
                ))}
              </EditableDetail>
              <EditableDetail
                label="Address 1"
                maxLength={40}
                name="primaryAddress.address1"
                required
              />
              <EditableDetail
                label="Address 2"
                maxLength={40}
                name="primaryAddress.address2"
              />
              <EditableDetail
                label="Address 3"
                maxLength={40}
                name="primaryAddress.address3"
              />
              <EditableDetail
                label="City"
                maxLength={32}
                name="primaryAddress.city"
                required
              />
              {states.length > 0 && (
                <EditableDetail
                  label="State/Province"
                  name="primaryAddress.state"
                  required={['USA', 'CAN'].includes(country)}
                  select
                >
                  {states?.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </EditableDetail>
              )}
              <EditableDetail
                label="Postal Code"
                maxLength={16}
                name="primaryAddress.postalCode"
                required={['USA', 'CAN'].includes(country)}
              />
            </div>
          </div>
        </Card>
      </EditableContent>
      <LoadingOverlay isOpen={formik.isSubmitting} text="Updating Address" />
    </>
  );
};
