import { IAnnouncement, IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getAnnouncement } from 'services';

type UseAnnouncement = [IAnnouncement, IQueryHookOptions];

export const useAnnouncement = (id = ''): UseAnnouncement => {
  const {
    data = {} as IAnnouncement,
    error,
    isError,
    isLoading,
  } = useQuery<IAnnouncement, IError>(
    ['announcements', id],
    () => getAnnouncement(id),
    {
      enabled: !!id,
    },
  );

  return [data, { error, isError, isLoading }];
};
