import { IEntitlement, IError, IQueryHookOptions } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getAccessKey } from 'services';

type UseAccessKey = [IEntitlement, IQueryHookOptions];

export const useAccessKey = (entitlementId = ''): UseAccessKey => {
  const {
    data = {} as IEntitlement,
    error,
    isError,
    isLoading,
  } = useQuery<IEntitlement, IError>(
    ['entitlements', entitlementId],
    () => getAccessKey(entitlementId),
    {
      enabled: !!entitlementId,
    },
  );

  return [data, { error, isError, isLoading }];
};
