import { IError, IProfile, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { postToPearson } from 'services';

type PostToPearsonFunction = UseMutateAsyncFunction<IProfile, IError>;

type UsePostToPearson = [PostToPearsonFunction, IQueryHookOptions];

export const usePostToPearson = (profileId = ''): UsePostToPearson => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfile,
    IError
  >(() => postToPearson(profileId));

  return [mutateAsync, { error, isError, isLoading }];
};
