const isDev = process.env.NODE_ENV === 'development';

export const env = {
  ADMIN_API_HOST: isDev
    ? '/admin'
    : (process.env.REACT_APP_ADMIN_API_HOST as string),
  ADMIN_API_KEY: process.env.REACT_APP_ADMIN_API_KEY as string,
  APP_INSIGHTS_CONNECTION_STRING: process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING as string,
  B2C_CLIENT_ID: process.env.REACT_APP_B2C_CLIENT_ID as string,
  B2C_DOMAIN: process.env.REACT_APP_B2C_DOMAIN as string,
  B2C_POLICY: process.env.REACT_APP_B2C_POLICY as string,
  B2C_TENANT: process.env.REACT_APP_B2C_TENANT as string,
  PEARSON_SSO_HOST: process.env.REACT_APP_PEARSON_SSO_HOST as string,
  ZENDESK_BASE_URL: process.env.REACT_APP_ZENDESK_BASE_URL as string,
};
