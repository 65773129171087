import {
  IError,
  IProfile,
  LoadingOverlay,
  getPrimaryPhoneNumberFromProfile,
  getSecondaryPhoneNumberFromProfile,
  useToast,
  languages,
  getMobilePhoneNumberFromProfile,
  phoneTypes,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { useState, ReactElement, useMemo } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components.
import {
  Card,
  Detail,
  EditableContent,
  EditableDetail,
  EditablePhone,
} from 'components';
import { ViewAdditionalDetails } from './ViewAdditionalDetails';

// Hooks.
import { useUpdateProfile } from 'hooks';

// Styles.
import styles from './ManageUserDetails.module.scss';

// Validations.
import { getValidationSchema } from './validationSchema';

// Types.
import { IAccount } from 'types';

interface IManageUserDetailsProps {
  account: IAccount;
  profile: IProfile;
}

export const ManageUserDetails = (
  props: IManageUserDetailsProps,
): ReactElement => {
  const { account, profile } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateProfile] = useUpdateProfile(profile?.id);
  const primaryPhoneNumber = useMemo(
    () => getPrimaryPhoneNumberFromProfile(profile),
    [profile],
  );
  const secondaryPhoneNumber = useMemo(
    () => getSecondaryPhoneNumberFromProfile(profile),
    [profile],
  );
  const mobilePhoneNumber = useMemo(
    () => getMobilePhoneNumberFromProfile(profile),
    [profile],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      alternateEmail: profile?.alternateEmail || '',
      company: profile?.company ?? '',
      ...(account?.permissions?.changeProfileName
        ? {
            givenName: {
              firstName: profile?.givenName?.firstName || '',
              lastName: profile?.givenName?.lastName || '',
              middleName: profile?.givenName?.middleName || '',
              suffix: profile?.givenName?.suffix || '',
            },
          }
        : {}),
      mobilePhoneNumber: {
        countryCode: mobilePhoneNumber?.countryCode || '',
        index: mobilePhoneNumber?.index,
        phone: mobilePhoneNumber?.phone || '',
        phonetype: mobilePhoneNumber?.phoneType || phoneTypes.Cellphone,
      },
      preferences: {
        language: profile?.preferences?.language,
      },
      preferredName: {
        firstName: profile?.preferredName?.firstName || '',
        lastName: profile?.preferredName?.lastName || '',
        middleName: profile?.preferredName?.middleName || '',
        suffix: profile?.preferredName?.suffix || '',
      },
      primaryPhoneNumber: {
        countryCode: primaryPhoneNumber?.countryCode || '',
        index: primaryPhoneNumber?.index,
        phone: primaryPhoneNumber?.phone || '',
        phonetype: primaryPhoneNumber?.phoneType || phoneTypes.Home,
      },
      secondaryPhoneNumber: {
        countryCode: secondaryPhoneNumber?.countryCode || '',
        index: secondaryPhoneNumber?.index,
        phone: secondaryPhoneNumber?.phone || '',
        phonetype: secondaryPhoneNumber?.phoneType || phoneTypes.Home,
      },
    },
    onSubmit: async (values) => {
      if (account?.permissions?.editProfile) {
        try {
          await updateProfile(values);
          setIsEditing(false);
          popToast('Profile successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(
      profile,
      account?.permissions?.changeProfileName,
      !!profile?.compTIAId,
    ),
  });

  return (
    <>
      <EditableContent
        canEdit={account?.permissions?.editProfile ?? false}
        isArchived={profile?.archived ?? false}
        isEditing={isEditing}
        onCancel={() => {
          formik.resetForm();
          setIsEditing(false);
        }}
        onEdit={() => setIsEditing(true)}
        formik={formik}
        title="General Information"
      >
        <Card className={styles.card}>
          <div className={styles.container}>
            <div className={styles.columns}>
              <div className={styles.column}>
                {account?.permissions?.changeProfileName ? (
                  <>
                    <EditableDetail
                      label="Legal First Name"
                      maxLength={30}
                      name="givenName.firstName"
                      required
                    />
                    <EditableDetail
                      label="Legal Middle Name"
                      maxLength={30}
                      name="givenName.middleName"
                    />
                    <EditableDetail
                      label="Legal Last Name"
                      maxLength={50}
                      name="givenName.lastName"
                      required
                    />
                    <EditableDetail
                      label="Legal Suffix"
                      maxLength={10}
                      name="givenName.suffix"
                    />
                  </>
                ) : (
                  <>
                    <Detail
                      label="Legal First Name"
                      value={profile?.givenName?.firstName}
                    />
                    <Detail
                      label="Legal Middle Name"
                      value={profile?.givenName?.middleName}
                    />
                    <Detail
                      label="Legal Last Name"
                      value={profile?.givenName?.lastName}
                    />
                    <Detail
                      label="Legal Suffix"
                      value={profile?.givenName?.suffix}
                    />
                  </>
                )}
                <Detail label="Email" value={profile?.email} />
                <EditableDetail
                  name="alternateEmail"
                  label="Alternate Email"
                  value={profile?.alternateEmail}
                />
              </div>
              <div className={styles.column}>
                <EditableDetail
                  label="Preferred First Name"
                  maxLength={30}
                  name="preferredName.firstName"
                />
                <EditableDetail
                  label="Preferred Middle Name"
                  maxLength={30}
                  name="preferredName.middleName"
                />
                <EditableDetail
                  label="Preferred Last Name"
                  maxLength={50}
                  name="preferredName.lastName"
                />
                <EditableDetail
                  label="Preferred Suffix"
                  maxLength={10}
                  name="preferredName.suffix"
                />
                <EditableDetail
                  label="Company"
                  name="company"
                  required={!!profile?.compTIAId}
                />
                <EditablePhone
                  countryCodeLabel="Country Code"
                  countryCodeName="primaryPhoneNumber.countryCode"
                  phoneLabel="Phone"
                  phoneName="primaryPhoneNumber.phone"
                  required={!!profile?.compTIAId}
                  phoneType="primaryPhoneNumber.phonetype"
                />
                <EditablePhone
                  countryCodeLabel="Country Code"
                  countryCodeName="mobilePhoneNumber.countryCode"
                  phoneLabel="Mobile Phone"
                  phoneName="mobilePhoneNumber.phone"
                  phoneType="mobilePhoneNumber.phonetype"
                />
                <EditablePhone
                  countryCodeLabel="Country Code"
                  countryCodeName="secondaryPhoneNumber.countryCode"
                  phoneLabel="Alternate Phone"
                  phoneName="secondaryPhoneNumber.phone"
                  phoneType="secondaryPhoneNumber.phonetype"
                />
                <EditableDetail
                  label="Language"
                  name="preferences.language"
                  select
                  renderValue={(value) =>
                    languages.find(
                      (language) => language.abbreviation === value,
                    )?.name ?? ''
                  }
                  value={profile?.preferences?.language || ''}
                >
                  {languages.map((language) => (
                    <option
                      key={language.abbreviation}
                      value={language.abbreviation}
                    >
                      {language.name}
                    </option>
                  ))}
                </EditableDetail>
              </div>
            </div>
          </div>
          <div className={styles.viewbuttoncontainer}>
            <button
              aria-controls="view-additional-details"
              aria-expanded={open}
              aria-label="View All Account Data"
              className={styles.viewbutton}
              onClick={() => setOpen(!open)}
              type="button"
            >
              View All Account Data{' '}
              {open ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </button>
          </div>
        </Card>
      </EditableContent>
      <ViewAdditionalDetails open={open} profile={profile} account={account} />
      <LoadingOverlay isOpen={formik.isSubmitting} text="Updating Profile" />
    </>
  );
};
