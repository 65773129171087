import { IEntitlement } from '@comptia-sso/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

// Styles.
import styles from './AccessKeyResult.module.scss';

interface IAccessKeyResultProps {
  result: IEntitlement;
}

export const AccessKeyResult = (props: IAccessKeyResultProps): ReactElement => {
  const { result } = props;

  return (
    <Link className={styles.container} to={`/access-keys/${result?.id}`}>
      <div className={styles.column}>
        <div className={styles.item}>
          <strong>Access Key</strong>
          <span>{result.accessKey}</span>
        </div>
        <div className={styles.item}>
          <strong>Product Title</strong>
          <span>{result.product?.title}</span>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.item}>
          <strong>Vendor</strong>
          <span>{result.vendor}</span>
        </div>
        <div className={styles.item}>
          <strong>Redemption</strong>
          <span>
            {result.redemptions?.length}/{result.limitPerUse}
          </span>
        </div>
      </div>
      <div className={styles.column}>
        <div />
        <div className={styles.item}>
          <strong>Status</strong>
          <span>{result.active ? 'Active' : 'Inactive'}</span>
        </div>
      </div>
      <div className={styles.link}>
        <ArrowForwardIos />
      </div>
    </Link>
  );
};
