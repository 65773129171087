import {
  FormPhoneFields,
  IFormPhoneFieldsProps,
  formatPhone,
} from '@comptia-sso/core';
import { useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';

// Components.
import { Detail } from 'components';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './EditablePhone.module.scss';

type IEditablePhoneProps = IFormPhoneFieldsProps & {
  countryCodeLabel: string;
  phoneLabel: string;
};

export const EditablePhone = (props: IEditablePhoneProps): ReactElement => {
  const { countryCodeLabel, countryCodeName, phoneLabel, phoneName } = props;
  const { isEditing } = useEditableContext();
  const { isSubmitting } = useFormikContext();
  const [countryCodeField] = useField(countryCodeName);
  const [phoneField] = useField(phoneName);

  return (
    <div className={styles.container}>
      {isEditing ? (
        <FormPhoneFields {...props} disabled={props.disabled || isSubmitting} />
      ) : (
        <>
          <Detail
            label={countryCodeLabel}
            value={`+${countryCodeField.value}`}
          />
          <Detail
            label={phoneLabel}
            value={formatPhone(phoneField.value, countryCodeField.value)}
          />
        </>
      )}
    </div>
  );
};
