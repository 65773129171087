import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// Config.
import { env } from 'config';

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: env.APP_INSIGHTS_CONNECTION_STRING,
  },
});
