import { ReactElement } from 'react';

// Hooks.
import { useAnnouncements, useAccount } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Store.
import { AnnouncementItem } from './AnnouncementItem';

import styles from './AnnouncementListView.module.scss';
import { Button, ButtonVariants, LoadingOverlay } from '@comptia-sso/core';

export const AnnouncementListView = (): ReactElement => {
  const [announcements, { isLoading }] = useAnnouncements();
  const [account] = useAccount();

  const displayAnnouncements = () => {
    return (
      <>
        <header>
          <h2>Announcements ({announcements?.length})</h2>
        </header>
        <div className={styles.results}>
          {announcements?.map((announcement) => (
            <AnnouncementItem
              key={announcement.id}
              announcement={announcement}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <ContentLayout
        header={
          <div className={styles.header}>
            {!!account?.permissions?.editAnnouncements && (
              <Button
                className={styles.add}
                link
                to="/announcements/add"
                variant={ButtonVariants.Primary}
              >
                Add Announcement
              </Button>
            )}
          </div>
        }
        content={announcements && displayAnnouncements()}
      />
      <LoadingOverlay isOpen={isLoading} text="Please Wait" />
    </>
  );
};
