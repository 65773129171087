import { IProfile } from '@comptia-sso/core';
import { ReactElement, useState } from 'react';

// Components
import { Card } from 'components';
import { EventLogModal } from '../EventLogModal';

// Hook.
import { useUserLoggingAnalytics } from 'hooks';

// Styles.
import styles from './EventLog.module.scss';

interface IEventLog {
  profile?: IProfile;
}

export const EventLog = (props: IEventLog): ReactElement => {
  const { profile } = props;
  const [open, setOpen] = useState(false);
  const [userLoggingAnalytics] = useUserLoggingAnalytics(profile?.id);

  if (!profile) return <></>;

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const renderWhenNone = () => {
    return (
      <>
        <strong className={styles.value}>None to review yet.</strong>
      </>
    );
  };

  const renderWhenSome = () => {
    return (
      <>
        <p>
          <span
            className={styles.viewAll}
            onClick={() => handleModalOpen()}
            aria-label="View All Events"
          >
            View All &gt;
          </span>
          <EventLogModal
            open={open}
            profile={profile}
            onClose={handleModalOpen}
            userLoggingAnalytics={userLoggingAnalytics}
          />
        </p>
      </>
    );
  };

  return (
    <Card className={styles.content}>
      <h3>Event Log</h3>
      <p>Review all event changes.</p>
      {userLoggingAnalytics.length > 0 ? renderWhenSome() : renderWhenNone()}
    </Card>
  );
};
