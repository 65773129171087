import {
  Button,
  ButtonTypes,
  ButtonVariants,
  IError,
  LoadingOverlay,
  TextArea,
  useQueryParams,
  useToast,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Components.
import { SubmittedMergeRecord } from './SubmittedMergeRecord';

// Hooks.
import {
  useAppDispatch,
  useMergeRecords,
  useProfile,
  useProfileEntitlements,
} from 'hooks';

// Store.
import { profileSearchActions } from 'store/slices';

// Styles.
import styles from './ProfileMergeView.module.scss';

const { resetSelectedResults } = profileSearchActions;

export const ProfileMergeView = (): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id1, id2 } = useQueryParams();
  const [profile1, { error: profile1Error, isLoading: isProfile1Loading }] =
    useProfile(id1);
  const [profile2, { error: profile2Error, isLoading: isProfile2Loading }] =
    useProfile(id2);
  const [
    profileEntitlements1,
    {
      error: profileEntitlements1Error,
      isLoading: isProfileEntitlements1Loading,
    },
  ] = useProfileEntitlements(id1);
  const [
    profileEntitlements2,
    {
      error: profileEntitlements2Error,
      isLoading: isProfileEntitlements2Loading,
    },
  ] = useProfileEntitlements(id2);

  const profile1ContainsCertmasterCE = useMemo(
    () =>
      profileEntitlements1.some((entitlement) => entitlement.isCertmasterCE),
    [profileEntitlements1],
  );

  const profile2ContainsCertmasterCE = useMemo(
    () =>
      profileEntitlements2.some((entitlement) => entitlement.isCertmasterCE),
    [profileEntitlements2],
  );

  const isLoading =
    isProfile1Loading ||
    isProfile2Loading ||
    isProfileEntitlements1Loading ||
    isProfileEntitlements2Loading;
  const [mergeRecords] = useMergeRecords();
  const [popToast] = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: '',
      loserId: '',
      winnerId: '',
    },
    onSubmit: async (values) => {
      try {
        const response = await mergeRecords(values);
        popToast('Records merged successfully.');
        navigate(`/profiles/${response?.winner?.id}`);
        dispatch(resetSelectedResults());
      } catch (error) {
        popToast(
          (error as IError)?.message ||
            'An unexpected error occurred. Please try again later.',
        );
      }
    },
  });

  const handleSelectWinner = (winnerId: string) => {
    const loserId = profile1.id === winnerId ? profile2.id : profile1.id;
    formik.setFieldValue('winnerId', winnerId);
    formik.setFieldValue('loserId', loserId);
  };

  useEffect(() => {
    if (profile1Error) {
      popToast(
        (profile1Error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [profile1Error]);

  useEffect(() => {
    if (profile2Error) {
      popToast(
        (profile2Error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [profile2Error]);

  useEffect(() => {
    if (profileEntitlements1Error) {
      popToast(
        (profileEntitlements1Error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [profileEntitlements1Error]);

  useEffect(() => {
    if (profileEntitlements2Error) {
      popToast(
        (profileEntitlements2Error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [profileEntitlements2Error]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.title}>
          <h1>Merge Records</h1>
          <p>
            Looking for another account? <Link to="/">Back to Search</Link>
          </p>
        </div>
      </header>
      <div className={styles.content}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <h2>User's Submitted Merge Records</h2>
          <div className={styles.mergeRecords}>
            <SubmittedMergeRecord
              entitlements={profileEntitlements1}
              isIneligibleAsWinner={profile2ContainsCertmasterCE}
              isSelected={formik.values.winnerId === profile1.id}
              onSelect={handleSelectWinner}
              profile={profile1}
              recordNumber={1}
            />
            <SubmittedMergeRecord
              entitlements={profileEntitlements2}
              isIneligibleAsWinner={profile1ContainsCertmasterCE}
              isSelected={formik.values.winnerId === profile2.id}
              onSelect={handleSelectWinner}
              profile={profile2}
              recordNumber={2}
            />
          </div>
          <div className={styles.mergeRecordsComments}>
            <TextArea
              label="Comments"
              onChange={formik.handleChange}
              name="comments"
              value={formik.values.comments}
            />
          </div>
          <div className={styles.mergeActions}>
            <Button
              className={styles.buttonApprove}
              disabled={!formik.values.winnerId || !formik.values.loserId}
              type={ButtonTypes.Submit}
            >
              Approve Merge
            </Button>
            <Button
              className={styles.buttonCancel}
              variant={ButtonVariants.Secondary}
              link
              to="/"
            >
              Cancel Merge
            </Button>
          </div>
        </form>
      </div>
      <LoadingOverlay isOpen={isLoading} text="Please Wait" />
      <LoadingOverlay isOpen={formik.isSubmitting} text="Merging Records" />
    </>
  );
};
