import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components.
import { AccessKeyContent } from './AccessKeyContent';
import { AccessKeyHeader } from './AccessKeyHeader';

// Hooks.
import { useAccessKey, useAccount } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

export const AccessKeyEditView = (): ReactElement => {
  const { id } = useParams();
  const [account] = useAccount();
  const [popToast] = useToast();
  const [accessKey, { error, isLoading }] = useAccessKey(id);

  useEffect(() => {
    if (error) {
      popToast(
        (error as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [error]);

  return (
    <>
      <ContentLayout
        header={<AccessKeyHeader />}
        content={
          !isLoading &&
          !error && <AccessKeyContent accessKey={accessKey} account={account} />
        }
      />
      <LoadingOverlay isOpen={isLoading} text="Please Wait" />
    </>
  );
};
