import { IEntitlement, IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { deactivateProfileEntitlement } from 'services';

type DeactivateProfileEntitlementFunction = UseMutateAsyncFunction<
  IEntitlement,
  IError
>;

type UseDeactivateProfileEntitlement = [
  DeactivateProfileEntitlementFunction,
  IQueryHookOptions,
];

export const useDeactivateProfileEntitlement = (
  profileId = '',
  accessKey = '',
): UseDeactivateProfileEntitlement => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IEntitlement,
    IError
  >(() => deactivateProfileEntitlement(profileId, accessKey), {
    onSuccess: () => {
      queryClient.invalidateQueries(['profiles', profileId, 'entitlements']);
    },
  });

  return [mutateAsync, { error, isError, isLoading }];
};
