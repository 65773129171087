import {
  Checkbox,
  IProfile,
  getGivenNameFromProfile,
  getPrimaryAddressFromProfile,
} from '@comptia-sso/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { Card, Detail } from 'components';

// Styles.
import styles from './ProfileSearchResult.module.scss';

interface IProfileSearchResult {
  isChecked?: boolean;
  isDisabled?: boolean;
  isMergeQueue?: boolean;
  onRemove?: (profile: IProfile) => void;
  onSelect?: (profile: IProfile, isSelected: boolean) => void;
  profile: IProfile;
}

export const ProfileSearchResult = (
  props: IProfileSearchResult,
): ReactElement => {
  const { isChecked, isDisabled, isMergeQueue, onRemove, onSelect, profile } =
    props;
  const address = useMemo(
    () => getPrimaryAddressFromProfile(profile),
    [profile],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSelect?.(profile, e.target.checked);
  };

  const handleRemove = () => {
    onRemove?.(profile);
  };

  const content = (
    <div className={styles.grid}>
      <Detail
        emptyMessage=""
        label="Name"
        value={
          <>
            {getGivenNameFromProfile(profile)}{' '}
            {profile?.archived && (
              <span className={styles.archived}>Archived</span>
            )}
          </>
        }
        valueClassName={styles.value}
      />
      <Detail
        emptyMessage=""
        label="Address"
        value={
          <>
            {[
              address?.address1,
              address?.address2,
              [address?.city, address?.state, address?.country]
                .filter(Boolean)
                .join(', '),
            ]
              .filter(Boolean)
              .join('\n')}
          </>
        }
      />
      <Detail
        emptyMessage=""
        label="Candidate ID"
        value={profile?.compTIAId}
        valueClassName={styles.value}
      />
      <Detail
        emptyMessage=""
        label="Email Address"
        value={profile?.email}
        valueClassName={styles.value}
      />
    </div>
  );

  return (
    <Card
      className={classNames(styles.container, {
        [styles.disabled]: isDisabled,
      })}
      collapsed
    >
      {isMergeQueue ? (
        <>
          <button className={styles.close} onClick={handleRemove}>
            <CloseIcon />
          </button>
          {content}
        </>
      ) : (
        <>
          <Checkbox
            aria-label={`Select profile ${profile.id} for merge`}
            className={styles.checkbox}
            disabled={isDisabled}
            onChange={handleChange}
            value={isChecked}
          />
          <Link to={`/profiles/${profile.id}`}>{content}</Link>
          <ArrowForwardIosIcon className={styles.icon} />
        </>
      )}
    </Card>
  );
};
