import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { setPassword } from 'services';

// Types.
import { ISetPasswordRequest } from 'types';

type UseSetPasswordFunction = UseMutateAsyncFunction<
  void,
  unknown,
  ISetPasswordRequest
>;

type UseSetPassword = [UseSetPasswordFunction, IQueryHookOptions];

export const useSetPassword = (): UseSetPassword => {
  const { error, isError, isLoading, mutateAsync } = useMutation<
    void,
    IError,
    ISetPasswordRequest
  >((values: ISetPasswordRequest) => setPassword(values));

  return [mutateAsync, { error, isError, isLoading }];
};
