import {
  FormTextArea,
  FormTextField,
  ITextFieldProps,
} from '@comptia-sso/core';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { ReactElement } from 'react';

// Components.
import { Detail } from 'components';

// Hooks.
import { useEditableContext } from 'hooks';

// Styles.
import styles from './EditableDetail.module.scss';

type IEditableDetailProps = ITextFieldProps & {
  containerClassName?: string;
  emptyMessage?: string;
  label: string;
  name: string;
  renderValue?: (value?: string) => string;
  textarea?: boolean;
};

export const EditableDetail = (props: IEditableDetailProps): ReactElement => {
  const {
    containerClassName,
    emptyMessage,
    renderValue,
    textarea,
    ...textFieldProps
  } = props;
  const { label, name } = textFieldProps;
  const { isEditing } = useEditableContext();
  const { isSubmitting } = useFormikContext();
  const [field] = useField(name);

  return (
    <div className={classNames(styles.detail, containerClassName)}>
      {isEditing ? (
        textarea ? (
          <FormTextArea
            {...textFieldProps}
            disabled={props.disabled || isSubmitting}
          />
        ) : (
          <FormTextField
            {...textFieldProps}
            disabled={props.disabled || isSubmitting}
          />
        )
      ) : (
        <Detail
          emptyMessage={emptyMessage}
          label={label}
          value={renderValue ? renderValue(field.value) : field.value}
        />
      )}
    </div>
  );
};
