import { createContext, PropsWithChildren, ReactElement } from 'react';

export interface IEditableContext {
  isEditing: boolean;
}

export const EditableContext = createContext<IEditableContext>(
  {} as IEditableContext,
);

export const EditableProvider = (
  props: PropsWithChildren<IEditableContext>,
): ReactElement => {
  const { children, isEditing } = props;

  return (
    <EditableContext.Provider value={{ isEditing }}>
      {children}
    </EditableContext.Provider>
  );
};
