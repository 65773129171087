import { IError, IProfile, Spinner, useToast } from '@comptia-sso/core';
import chunk from 'lodash/chunk';
import { ReactElement, useEffect, useMemo, useState } from 'react';

// Components.
import { Card, Pagination } from 'components';
import { SuspensionCard } from './SuspensionCard';
import { AddSuspension } from '../AddSuspension';

// Hooks.
import { useProfileSuspensions } from 'hooks';

// Styles.
import styles from './ManageUserSuspensions.module.scss';

// Types.
import { IAccount } from 'types';

interface IManageUserSuspensionsProps {
  account: IAccount;
  profile: IProfile;
}

const totalPerPage = 10;

export const ManageUserSuspensions = (
  props: IManageUserSuspensionsProps,
): ReactElement => {
  const { account, profile } = props;
  const [popToast] = useToast();
  const [
    suspensions,
    { error: suspensionsError, isLoading: isLoadingSuspensions },
  ] = useProfileSuspensions(profile?.id);
  const [page, setPage] = useState(1);

  const pageCount = useMemo(
    () => Math.ceil((suspensions?.length ?? 0) / totalPerPage),
    [suspensions],
  );

  const suspensionsForPage = useMemo(
    () => chunk(suspensions, totalPerPage)[page - 1],
    [page, suspensions],
  );

  useEffect(() => {
    if (suspensionsError) {
      popToast(
        (suspensionsError as IError)?.message ||
          'An unexpected error occurred. Please try again later.',
      );
    }
  }, [suspensionsError]);

  return (
    <section title="User Suspensions">
      <header className={styles.header}>
        <h2>User Suspensions</h2>
        <AddSuspension account={account} profile={profile} />
      </header>
      {profile && !isLoadingSuspensions ? (
        <div className={styles.cards}>
          {(suspensions?.length ?? 0) > 0 ? (
            <>
              {suspensionsForPage.map((suspension, index) => (
                <SuspensionCard
                  account={account}
                  suspension={suspension}
                  key={suspension?.id || index}
                  profile={profile}
                />
              ))}
              {pageCount > 1 && (
                <Pagination
                  onPageChange={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              )}
            </>
          ) : (
            <Card className={styles['no-suspensions']}>
              This profile has no Suspensions.
            </Card>
          )}
        </div>
      ) : (
        <Card>
          <Spinner className={styles.spinner} />
        </Card>
      )}
    </section>
  );
};
