import {
  ISuspension,
  IError,
  IQueryHookOptions,
  IUpdateProfileSuspensionRequest,
} from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { updateProfileSuspension } from 'services';

type UpdateProfileSuspensionFunction = UseMutateAsyncFunction<
  ISuspension,
  IError,
  IUpdateProfileSuspensionRequest
>;

type UseUpdateProfileSuspension = [
  UpdateProfileSuspensionFunction,
  IQueryHookOptions,
];

export const useUpdateProfileSuspensions = (
  profileId = '',
  suspensionId = '',
): UseUpdateProfileSuspension => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    ISuspension,
    IError,
    IUpdateProfileSuspensionRequest
  >(
    (values: IUpdateProfileSuspensionRequest) =>
      updateProfileSuspension(profileId, suspensionId, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['profiles', profileId, 'suspensions']);
      },
    },
  );

  return [mutateAsync, { error, isError, isLoading }];
};
