import { IRedemption, formatDate } from '@comptia-sso/core';
import chunk from 'lodash/chunk';
import { useState, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

// Components.
import { Card, Pagination } from 'components';

// Styles.
import styles from './AccessKeyRedemptions.module.scss';

interface IAccessKeyRedemptionsProps {
  redemptions: IRedemption[];
}

const totalPerPage = 10;

export const AccessKeyRedemptions = (
  props: IAccessKeyRedemptionsProps,
): ReactElement => {
  const { redemptions = [] } = props;
  const [page, setPage] = useState(1);
  const pageCount = useMemo(
    () => Math.ceil(redemptions.length / totalPerPage),
    [redemptions],
  );
  const redemptionsForPage = useMemo(
    () => chunk(redemptions, totalPerPage)[page - 1],
    [page, redemptions],
  );

  return (
    <section className={styles.redemptions}>
      <h2>Redemption Profiles</h2>
      <Card className={styles.card}>
        {redemptions.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Redemption Date</td>
              </tr>
            </thead>
            <tbody>
              {redemptionsForPage?.map((redemption) => (
                <tr key={redemption.id}>
                  <td>
                    <strong>
                      {redemption.firstName}{' '}
                      {redemption.lastName ? redemption.lastName : ''}
                    </strong>
                  </td>
                  <td>
                    <Link to={`/profiles/${redemption.userId}`}>
                      {redemption.email}
                    </Link>
                  </td>
                  <td>{formatDate(redemption.redemptionDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No Profiles</p>
        )}
      </Card>
      {pageCount > 1 && (
        <Pagination onPageChange={setPage} page={page} pageCount={pageCount} />
      )}
    </section>
  );
};
