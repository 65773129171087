import { configureStore } from '@reduxjs/toolkit';

// Slices.
import { accessKeySearchReducer, profileSearchReducer } from './slices';

export const store = configureStore({
  reducer: {
    accessKeySearch: accessKeySearchReducer,
    profileSearch: profileSearchReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
