import { IError, IQueryHookOptions } from '@comptia-sso/core';
import { UseMutateAsyncFunction, useMutation } from 'react-query';

// Services.
import { searchProfiles } from 'services';

// Types.
import { IProfileSearchRequest, IProfileSearchResponse } from 'types';

type SearchProfileFunction = UseMutateAsyncFunction<
  IProfileSearchResponse,
  IError,
  IProfileSearchRequest
>;

type UseSearchProfile = [SearchProfileFunction, IQueryHookOptions];

export const useSearchProfiles = (): UseSearchProfile => {
  const { error, mutateAsync, isError, isLoading } = useMutation<
    IProfileSearchResponse,
    IError,
    IProfileSearchRequest
  >((values: IProfileSearchRequest) => searchProfiles(values));

  return [mutateAsync, { error, isError, isLoading }];
};
