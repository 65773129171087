import { IProfile } from '@comptia-sso/core';
import { ReactElement } from 'react';

// Components.
import { ChangeEmail } from './ChangeEmail';
import { EventLog } from './EventLog';
import { ManageUserAddress } from './ManageUserAddress';
import { ManageUserDetails } from './ManageUserDetails';
import { ManageUserEntitlements } from './ManageUserEntitlements';
import { ManageUserSuspensions } from './ManageUserSuspensions';
import { PasswordReset } from './PasswordReset';
import { UserActivity } from './UserActivity';
import { ViewZendeskTickets } from './ViewZendeskTickets';

// Styles.
import styles from './ManageProfileContent.module.scss';

// Types.
import { IAccount } from 'types';

interface IManageProfileContentProps {
  account: IAccount;
  profile: IProfile;
}

export const ManageProfileContent = (
  props: IManageProfileContentProps,
): ReactElement => {
  const { account, profile } = props;
  const archivedClass = profile?.archived ? styles.archived : '';
  return (
    <div className={`${styles.content}`}>
      <ManageUserDetails account={account} profile={profile} />
      <div className={`${styles['grid-container']} ${archivedClass}`}>
        <div className={styles.leftcol}>
          <ManageUserAddress account={account} profile={profile} />
        </div>
        <div className={styles.rightcol}>
          {account?.permissions?.changePassword && (
            <PasswordReset account={account} profile={profile} />
          )}
          {account?.permissions?.changeEmail && (
            <ChangeEmail account={account} profile={profile} />
          )}
          <UserActivity profile={profile} />
          <EventLog profile={profile} />
        </div>
      </div>
      <ManageUserEntitlements account={account} profile={profile} />
      <ViewZendeskTickets profile={profile} />
      <ManageUserSuspensions account={account} profile={profile} />
    </div>
  );
};
