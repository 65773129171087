import { IError, IQueryHookOptions } from '@comptia-sso/core';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

// Services.
import { mergeRecords } from 'services';

// Types.
import {
  IProfileSelectMergeRecordResponse,
  IProfileSelectMergeRecordRequest,
} from 'types';

type MergeRecordsFunction = UseMutateAsyncFunction<
  IProfileSelectMergeRecordResponse,
  IError,
  IProfileSelectMergeRecordRequest
>;

type UseMergeRecords = [MergeRecordsFunction, IQueryHookOptions];

export const useMergeRecords = (): UseMergeRecords => {
  const queryClient = useQueryClient();
  const { error, isError, isLoading, mutateAsync } = useMutation<
    IProfileSelectMergeRecordResponse,
    IError,
    IProfileSelectMergeRecordRequest
  >((values: IProfileSelectMergeRecordRequest) => mergeRecords(values), {
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(['profiles', data.winner?.id], data.winner);
        queryClient.setQueryData(['profiles', data.loser?.id], data.loser);
        queryClient.invalidateQueries([
          'profiles',
          data.winner?.id,
          'entitlements',
        ]);
        queryClient.invalidateQueries([
          'profiles',
          data.loser?.id,
          'entitlements',
        ]);
      }
    },
  });
  return [mutateAsync, { error, isError, isLoading }];
};
