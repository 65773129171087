import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

export const useAuthLogout = (): VoidFunction => {
  const { instance } = useMsal();
  return useCallback(() => {
    localStorage.clear();
    instance.logoutRedirect();
  }, [instance]);
};
