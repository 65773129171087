import { IError, IQueryHookOptions, IUserActivity } from '@comptia-sso/core';
import { useQuery } from 'react-query';

// Services.
import { getUserActivity } from 'services';

type UseUserActivity = [IUserActivity[], IQueryHookOptions];

export const useUserActivity = (profileId = ''): UseUserActivity => {
  const {
    data = [] as IUserActivity[],
    error,
    isError,
    isLoading,
  } = useQuery<IUserActivity[], IError>(
    ['profiles', profileId, 'userActivity'],
    () => getUserActivity(profileId),
    {
      enabled: !!profileId,
    },
  );

  return [data, { error, isError, isLoading }];
};
