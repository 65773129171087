import { Button, IEntitlement } from '@comptia-sso/core';
import { ReactElement } from 'react';
import { AccessKeyResult } from './AccessKeyResult';

// Styles.
import styles from './AccessKeySearchResults.module.scss';

interface IAccessKeySearchResultsProps {
  continuationToken: string | null;
  onLoadMore: VoidFunction;
  results: IEntitlement[];
}

export const AccessKeySearchResults = (
  props: IAccessKeySearchResultsProps,
): ReactElement => {
  const { continuationToken, onLoadMore, results = [] } = props;

  return (
    <div className={styles.container}>
      <header>
        <h2>Search Results ({results.length})</h2>
      </header>
      <div className={styles.results}>
        {results.map((result) => (
          <AccessKeyResult key={result.accessKey} result={result} />
        ))}
        {results.length > 0 && continuationToken && (
          <Button onClick={onLoadMore}>Load More</Button>
        )}
      </div>
    </div>
  );
};
