import { formatDateTime, IZendeskTicket } from '@comptia-sso/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import uniqueId from 'lodash/uniqueId';
import { ReactElement, useMemo, useState } from 'react';

// Config.
import { env } from 'config';

// Components.
import { Card, Detail } from 'components';

// Styles.
import styles from './zendeskTicketCard.module.scss';
import { ZendeskTicketCommentCard } from './ZendeskTicketCommentCard';

interface ZendeskTicketCardProps {
  zendeskTicket: IZendeskTicket;
}

export const ZendeskTicketCard = (
  props: ZendeskTicketCardProps,
): ReactElement => {
  const { zendeskTicket } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const id = useMemo(() => uniqueId(), []);

  return (
    <Card collapsed>
      <div className={styles.preview}>
        <span className={styles.zendeskId}>
          <strong>Zendesk Id</strong>
          <div>
            <a
              href={env.ZENDESK_BASE_URL + '/agent/tickets/' + zendeskTicket.id}
              rel="noopener noreferrer"
              target="_blank"
            >
              {zendeskTicket?.id}
            </a>
          </div>
        </span>
        <Detail
          emptyMessage="-"
          label="Date Created"
          value={formatDateTime(zendeskTicket?.createdAt)}
        />
        <Detail
          emptyMessage="-"
          label="Subject"
          value={zendeskTicket?.subject}
        />
        <Detail emptyMessage="-" label="Status" value={zendeskTicket?.status} />
        <button
          aria-controls={`zendeskTicket-${id}-additional`}
          aria-label={
            isExpanded ? 'Collapse Entitlement' : 'Expand Entitlement'
          }
          aria-expanded={isExpanded}
          className={styles.toggle}
          onClick={toggleExpanded}
        >
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </button>
      </div>
      {isExpanded && (
        <>
          {zendeskTicket?.comments.map((comment, index) => (
            <ZendeskTicketCommentCard
              comment={comment}
              key={comment?.id || index}
            />
          ))}
        </>
      )}
    </Card>
  );
};
