import {
  IUserLoggingAnalytics,
  formatDate,
  formatJson,
} from '@comptia-sso/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactElement, useMemo, useState } from 'react';
import DiffViewer from 'react-diff-viewer';

// Styles.
import styles from './EventLogModal.module.scss';

interface IEventLogItem {
  event: IUserLoggingAnalytics;
}

export const EventLogItem = (props: IEventLogItem): ReactElement => {
  const { event } = props;

  const formattedPreviousItem = useMemo(
    () =>
      // If `newItem` is null, then there will be no `previousItem`.
      event.newItem ? formatJson(JSON.parse(event.previousItem || '{}')) : '',
    [event],
  );

  const formattedNewItem = useMemo(
    () =>
      // If `newItem` is null, then `previousItem` will be the new item.
      formatJson(JSON.parse(event.newItem || event.previousItem || '{}')),
    [event],
  );

  if (!event) return <></>;

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={styles.itemContainer}>
        <div className={styles.date}>{formatDate(event.operationDate)}</div>
        <div className={styles.itemContent}>
          <span className={styles.itemHeader}>{event.displayName}</span>
          {event.isAdmin && (
            <span>
              {event.userFirstName} {event.userLastName} - {event.userEmail}
            </span>
          )}
        </div>
        <div className={styles.icon}>
          <span
            className={styles.viewbutton}
            onClick={() => setExpanded(!expanded)}
            aria-label="View Payload"
          >
            {expanded ? (
              <ExpandLessIcon fontSize="small" />
            ) : (
              <ExpandMoreIcon fontSize="small" />
            )}
          </span>
        </div>
      </div>
      {expanded && (
        <DiffViewer
          disableWordDiff
          hideLineNumbers
          oldValue={formattedPreviousItem}
          newValue={formattedNewItem}
          splitView={false}
        />
      )}
    </>
  );
};
