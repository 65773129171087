import { AuthenticationResult } from '@azure/msal-common';

// Config.
import { msalRequest } from 'config';

// Providers.
import { msalInstance } from 'providers';

// Utils.
import { appInsights } from 'utils';

enum ApiMethods {
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}
let token: AuthenticationResult;

export const doRequest = async <TResponse = unknown, TData = unknown>(
  method: ApiMethods,
  url: string,
  body?: TData,
  functionsKey?: string,
): Promise<TResponse> => {
  const account = msalInstance.getActiveAccount();
  if (account) {
    try {
      token = await msalInstance.acquireTokenSilent({
        ...msalRequest,
        account,
      });
    } catch (error) {
      // If the token fails to go quietly (expired), force its hand.
      localStorage.clear();
      await msalInstance.acquireTokenRedirect({
        ...msalRequest,
        account,
      });
    }
  }

  return fetch(url, {
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      ...(token && { Authorization: `Bearer ${token.idToken}` }),
      'Content-Type': 'application/json',
      ...(functionsKey && { 'x-functions-key': functionsKey }),
    },
    method,
  }).then(async (response) => {
    const text = await response.text();
    let result;

    try {
      result = JSON.parse(text);
    } catch {
      if (!response.ok) {
        result = { message: text, statusCode: response.status };
      } else {
        result = text;
      }
    }

    if (!response.ok) {
      if (response.status === 403) {
        result.message =
          result.message ||
          'You do not have permission to perform this action.';
      }
      appInsights.trackException({ exception: result });
      throw result;
    }

    return result;
  });
};

export const getRequest = <TResponse = unknown>(
  url: string,
  functionsKey?: string,
): Promise<TResponse> =>
  doRequest(ApiMethods.Get, url, undefined, functionsKey);

export const postRequest = <TResponse = unknown, TData = unknown>(
  url: string,
  body?: TData,
  functionsKey?: string,
): Promise<TResponse> => doRequest(ApiMethods.Post, url, body, functionsKey);

export const putRequest = <TResponse = unknown, TData = unknown>(
  url: string,
  body: TData,
  functionsKey?: string,
): Promise<TResponse> => doRequest(ApiMethods.Put, url, body, functionsKey);

export const patchRequest = <TResponse = unknown, TData = unknown>(
  url: string,
  body: TData,
  functionsKey?: string,
): Promise<TResponse> => doRequest(ApiMethods.Patch, url, body, functionsKey);
