export enum Permissions {
  ChangeEmail = 'changeEmail',
  ChangePassword = 'changePassword',
  ChangePermissions = 'userManagementChangePermissions',
  ChangeProfileName = 'changeProfileName',
  CreateAccessKeys = 'createAccessKeys',
  EditAccessKeys = 'editAccessKeys',
  EditEntitlements = 'editEntitlements',
  EditProfile = 'editProfile',
  MergeProfile = 'mergeProfile',
  ChangePearsonVueAndComptiaIds = 'changePearsonVueAndComptiaIds',
  EditAnnouncements = 'editAnnouncements',
  changeSuspensions = 'changeSuspensions',
}

export const PermissionLabelMap = {
  [Permissions.ChangeEmail]: 'Change Email',
  [Permissions.ChangePassword]: 'Change Password',
  [Permissions.ChangePermissions]: 'Change Permissions',
  [Permissions.ChangeProfileName]: 'Change Profile Name',
  [Permissions.CreateAccessKeys]: 'Create Access Keys',
  [Permissions.EditAccessKeys]: 'Edit Access Keys',
  [Permissions.EditEntitlements]: 'Edit Entitlements',
  [Permissions.EditProfile]: 'Edit Profile',
  [Permissions.MergeProfile]: 'Merge Profile',
  [Permissions.ChangePearsonVueAndComptiaIds]:
    'Change PearsonVue and CompTIA IDs',
  [Permissions.EditAnnouncements]: 'Manage Announcements',
  [Permissions.changeSuspensions]: 'Change Suspensions',
};

export interface IAccount {
  displayName: string;
  email: string;
  id: string;
  permissions: Record<Permissions, boolean>;
  permissionsCount: number;
}
