import { formatDateTime, IZendeskTicketComment } from '@comptia-sso/core';
import { ReactElement } from 'react';

// Components.
import { Card } from 'components';

// Styles.
import styles from './zendeskTicketCommentCard.module.scss';

interface ZendeskTicketCommentCardProps {
  comment: IZendeskTicketComment;
}

export const ZendeskTicketCommentCard = (
  props: ZendeskTicketCommentCardProps,
): ReactElement => {
  const { comment } = props;

  const cardStyle =
    styles.card + ' ' + (comment?.isPublic ? styles.public : '');

  return (
    <Card className={cardStyle}>
      <div className={styles.grid}>
        <div className={styles.column}>
          <div className={styles.detail}>
            <strong>Comment Date</strong>
            <div>{formatDateTime(comment?.createdAt)}</div>
          </div>
          <div className={styles.detail}>
            <strong>Author</strong>
            <div>{comment?.author}</div>
          </div>
          <div className={styles.detail}>
            <strong>Audience</strong>
            <div>{comment?.isPublic ? 'Public' : 'Internal'}</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.detail}>
            <strong>Comment</strong>
            <div dangerouslySetInnerHTML={{ __html: comment?.htmlBody }} />
          </div>
        </div>
      </div>
    </Card>
  );
};
