import { IError, LoadingOverlay, useToast } from '@comptia-sso/core';
import { FormikProvider, useFormik } from 'formik';
import { FormEvent, ReactElement } from 'react';

// Hooks.
import { useAppDispatch, useAppSelector, useSearchAccessKeys } from 'hooks';

// Layouts.
import { ContentLayout } from 'layouts';

// Store.
import { accessKeySearchActions } from 'store/slices';
import { AccessKeySearchForm } from './AccessKeySearchForm';
import { AccessKeySearchResults } from './AccessKeySearchResults';

const { initializeSearch, setRequest, setResponse } = accessKeySearchActions;

export const AccessKeySearchView = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { continuationToken, hasSearched, request, results, pageSize } =
    useAppSelector((state) => state.accessKeySearch);
  const [searchAccessKeys] = useSearchAccessKeys();
  const [popToast] = useToast();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: request,
    onSubmit: async (values) => {
      try {
        const response = await searchAccessKeys({
          ...values,
          continuationToken,
          pageSize,
        });
        dispatch(setRequest(values));
        dispatch(setResponse(response));
      } catch (error) {
        popToast(
          (error as IError)?.message ||
            'An unexpected error occurred. Please try again later.',
        );
      }
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    dispatch(initializeSearch(false));
    formik.handleSubmit(e);
  };

  const handleClear = () => {
    dispatch(initializeSearch(true));
    formik.resetForm();
  };

  const handleLoadMore = () => {
    formik.submitForm();
  };

  return (
    <>
      <ContentLayout
        header={
          <FormikProvider value={formik}>
            <AccessKeySearchForm
              hasSearched={hasSearched}
              isDirty={formik.dirty}
              onClear={handleClear}
              onSubmit={handleSubmit}
            />
          </FormikProvider>
        }
        content={
          (hasSearched || results.length > 0) && (
            <AccessKeySearchResults
              continuationToken={continuationToken}
              onLoadMore={handleLoadMore}
              results={results}
            />
          )
        }
      />
      <LoadingOverlay
        isOpen={formik.isSubmitting}
        text="Searching Access Keys"
      />
    </>
  );
};
